import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import { QrScanner } from '@yudiel/react-qr-scanner'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useDialog } from '../../../features/dialog/application-dialog'
import { lsClient } from '../../../ls-client'
import { Typography } from '../../../ui'
import { ModuleHeader } from '../../../ui/templates/modules/moduleHeader'
import { paths } from '../../paths'
import {
  getScanCode,
  selectScanError,
  setActive,
  setCassetteNumber,
  setError,
  setScanError,
} from '../model'
import { RegisterTestProps } from '../types'

// eslint-disable-next-line @typescript-eslint/naming-convention
const data_matrix = 'GS1DataMatrix2Dbarcode'
const QRCode = 'QRCode'

export const ScanQRCode = (props: RegisterTestProps) => {
  const { i18n, handleNext, handleBack } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()
  const [data, setData] = useState('')
  const error = useSelector(selectScanError)
  const healthServiceId = lsClient.getUserLSByKey('healthServiceId')
  const { openDialog, closeDialog } = useDialog()

  const resetScanFlow = () => {
    lsClient.removeUserKeyLS('scan_timer_start')
    lsClient.removeUserKeyLS('wait_timer_start')
    lsClient.removeUserKeyLS('scan_capture_time')
    lsClient.removeUserKeyLS('step')
  }

  useEffect(() => {
    if (error) {
      const content = {
        ...error,
        onCancel: () => cancelScan(),
        cancelLabel: i18n.cancel_button,
        onConfirm: () => tryAgain(),
        confirmationLabel: i18n.try_again,
      }
      openDialog(content)
    }
  }, [error])

  const tryAgain = () => {
    dispatch(setScanError(undefined))
    closeDialog()
    setData('')
  }

  const cancelScan = () => {
    dispatch(setScanError(undefined))
    setData('')
    closeDialog()
    onExit()
  }

  const onExit = () => {
    lsClient.navigation.removeBackPath()
    dispatch(setActive(1))
    navigate(paths.app.dashboard())
  }

  const onBack = () => {
    const backpath = lsClient.navigation.getBackPath()
    lsClient.removeUserKeyLS('testId')
    dispatch(setActive(1))
    if (backpath) {
      lsClient.navigation.removeBackPath()
      navigate(backpath)
    } else {
      handleBack()
    }
  }

  const handleError = (error: any) => {
    console.log(error)
  }

  const handleResult = (result: any) => {
    dispatch(setError(''))
    const success = () => {
      lsClient.navigation.removeBackPath()
      resetScanFlow()
      handleNext()
    }
    if (result.format === 5) {
      lsClient.setUserLS('code', result.text)
      dispatch(setCassetteNumber(result.text))
      dispatch(
        getScanCode(
          'en',
          result.text,
          data_matrix,
          i18n,
          healthServiceId,
          success
        )
      )
    } else if (result.format === 11) {
      lsClient.setUserLS('code', result.text)
      dispatch(setCassetteNumber(result.text))
      dispatch(
        getScanCode('en', result.text, QRCode, i18n, healthServiceId, success)
      )
    }
    setData(result.text)
  }

  return (
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={onBack}
      rightContentTitle={i18n.exit_button}
      rightContentAction={onExit}
      color="#505358"
    >
      <div className={classes.content}>
        <Box className={classes.cameraContent}>
          <Typography variant="h2" className={classes.title}>
            {i18n.scan_code_title}
          </Typography>
          <Typography variant="h2" className={classes.body}>
            {i18n.camera_scaning_hint}
          </Typography>
          <div className={classes.divider} />
          {!data && (
            <QrScanner
              containerStyle={{ height: 500, width: 500, paddingTop: 0 }}
              videoStyle={{ height: 500, width: 500 }}
              onResult={handleResult}
              onError={handleError}
              stopDecoding={Boolean(data)}
              viewFinderBorder={170}
            />
          )}
        </Box>
      </div>
    </ModuleHeader>
  )
}

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    maxWidth: '500px',
    margin: '64px auto',
    position: 'relative',
    [theme.breakpoints.down(1200)]: {
      margin: '32px auto',
    },
    [theme.breakpoints.down(600)]: {
      margin: '16px auto',
    },
  },
  title: {
    fontWeight: 600,
    color: '#282D37',
    fontSize: 36,
    alignSelf: 'flex-start',
    [theme.breakpoints.down(600)]: {
      width: '90%',
      marginLeft: '24px',
    },
    [theme.breakpoints.down(389)]: {
      width: '90%',
      fontSize: 32,
      lineHeight: '36px',
    },
  },
  divider: {
    width: '100%',
    height: 1,
    marginBottom: '8px',
    borderBottom: '1px solid #E0E0E0',
  },
  body: {
    width: '342px',
    fontWeight: 500,
    color: '#757575',
    lineHeight: '22px',
    fontSize: 16,
    alignSelf: 'flex-start',
    [theme.breakpoints.down(600)]: {
      marginBottom: '16px',
      width: '90%',
      marginLeft: '24px',
    },
    [theme.breakpoints.down(389)]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  cameraContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    height: '100%',
    width: '100%',
  },
}))
