import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { NOTIFICATION_TYPES } from '../../../constants'
import { useDialog } from '../../../features/dialog/application-dialog'
import { WorkflowItem } from '../../../features/flowcontrol/types'
import { useFlowControl } from '../../../features/flowcontrol/use-flow-control'
import { createHealthService } from '../../../features/health-service-lite/model'
import { showNotification } from '../../../features/notifications/model'
import { StoreStepType } from '../../../features/shopify/model/types'
import { I18n } from '../../../features/translation/types'
import { lsClient } from '../../../ls-client'
import { selectNetworkSettings } from '../../network/model'
import { DashboardCard } from '../../network/type'
import { paths } from '../../paths'
import { selectProfileData } from '../../profile/model'
import { setActive } from '../../register-a-test-module/model'
import { RegisterTestStepType } from '../../register-a-test-module/types'
import {
  BackgroundImageCard,
  BenefitCard,
  CarouselCard,
  GradientCard,
  PassportCard,
  SimpleButton,
  StandardLargeCard,
  StandardMediumCard,
  TestHistoryCard,
  TransparentCard,
} from './CardsV2'
import { CardProps } from './CardsV2/types'

export const DashboardCardItem = (props: {
  activeHealthService: boolean
  button: DashboardCard
  i18n: I18n
  xl?: boolean
}) => {
  const { button, i18n, activeHealthService } = props
  const navigate = useNavigate()
  const networkSettings = useSelector(selectNetworkSettings)
  const radius = networkSettings?.buttonStyle === 0 ? '8px' : '24px'
  const dispatch = useDispatch()
  const user = useSelector(selectProfileData)
  const tenantId = localStorage.getItem('tenantId')
  const { actions, state } = useFlowControl()
  const { openDialog, closeDialog } = useDialog()

  if (!button.enabled) return null
  if (activeHealthService && button.buttonAction?.value === 'HealthService')
    return <></>

  const showActiveServiceMessage = () => {
    dispatch(
      showNotification(
        'Please complete your open Health Service before beginning a new one.',
        NOTIFICATION_TYPES.V2_SUCCESS
      )
    )
  }

  const handleClick = () => {
    if (button.buttonAction?.value === 'Workflow') {
      startWorkflows(button.workflowIds)
    } else if (button.buttonAction?.value === 'RedirectToUrl') {
      if (!button.redirectUrl) return
      buttonAction(button.buttonAction.value, button.redirectUrl)
    } else if (button.buttonAction?.value) {
      buttonAction(button.buttonAction.value)
    }
  }

  const startWorkflows = (workflowIds: string[] | undefined) => {
    if (activeHealthService) {
      showActiveServiceMessage()
      return
    }
    if (!workflowIds || workflowIds.length === 0) {
      sessionStorage.removeItem('workflowListIds')
      navigate(paths.flowList())
      return
    }

    if (workflowIds.length === 1) {
      const id = workflowIds[0]
      const workflow: any = state.flowsList.find(
        (f: WorkflowItem) => f.id === id
      )
      if (workflow) {
        sessionStorage.removeItem('workflowListIds')
        lsClient.setUserLS('flowId', workflow.id)
        lsClient.setUserLS(
          'flowTitle',
          workflow.name.replaceAll('’', "'") || 'unknown service'
        )
        const params = {
          tenantId,
          accountId: user.accountId,
          UserId: user._id,
          Description: 'Starting Health Service',
        }
        const onCompletion = (healthServiceId: string) => {
          lsClient.setUserLS('healthServiceId', healthServiceId)
          actions.startFlow(id)
        }
        dispatch(createHealthService(params, onCompletion))
      }
    } else {
      sessionStorage.setItem('workflowListIds', workflowIds.join(', '))
      navigate(paths.flowList())
    }
  }
  const beginHealthService = (type: 'HealthService' | 'Workflow') => {
    if (activeHealthService) {
      showActiveServiceMessage()
      return
    }
    if (type === 'Workflow') {
      navigate(paths.flowList())
    } else {
      const params = {
        tenantId,
        accountId: user.accountId,
        UserId: user._id,
        Description: 'Starting Health Service',
      }
      const onCompletion = (healthServiceId: string) => {
        lsClient.setUserLS('healthServiceId', healthServiceId)
        navigate(paths.symptomCheckerIntro())
      }
      dispatch(createHealthService(params, onCompletion))
    }
  }

  const openURL = (url: string) => {
    closeDialog()
    window.open(url, '_blank')
  }

  const buttonAction = (
    to: string,
    id?: string,
    type?: number,
    text?: string
  ) => {
    if (!to) return null
    switch (to) {
      case 'TakeaTest':
      case 'GetTested': {
        navigate(paths.registerTest(RegisterTestStepType.CHOOSE_A_TEST))
        break
      }
      case 'HealthService': {
        beginHealthService('HealthService')
        break
      }
      case 'RegisterATest': {
        dispatch(setActive(3))
        navigate(paths.registerTest(RegisterTestStepType.SCAN_CODE))
        break
      }
      case 'CarePlans': {
        navigate(paths.treatmentPlanHistory())
        break
      }
      case 'Workflow': {
        if (id) {
          lsClient.setUserLS('flowId', id)
          lsClient.setUserLS('flowTitle', text ?? 'unknown service')
          actions.startFlow(id)
          break
        }
        navigate(paths.flowList())
        break
      }
      case 'PurchaseHistory': {
        navigate(paths.shopifyStore(StoreStepType.ORDER_HISTORY))
        break
      }
      case 'StartVirtualConsult':
      case 'SymptomChecker': {
        navigate(paths.symptomCheckerIntro())
        break
      }
      case 'Resources': {
        if (id) {
          lsClient.navigation.setBackPath('return_to_dashboard')
          navigate(
            paths.resourceDetails(type === 1 ? 'video' : 'article', id)
          )
          break
        }
        navigate(paths.resources())
        break
      }
      case 'RedirectToUrl': {
        if (!id) return
        const content = {
          title: i18n.leaving_the_app,
          body: i18n.leaving_the_app_instruction,
          onCancel: () => closeDialog(),
          cancelLabel: i18n.not_right_now,
          onConfirm: () => openURL(id),
          confirmationLabel: i18n.continue_to_site,
        }
        openDialog(content)
        break
      }
      // case 'ImportHealthRecords': {
      //   navigate(paths.resources())
      //   break
      // }
      case 'OrderTestKit': {
        navigate(paths.shopifyStore(StoreStepType.PRODUCTS))
        break
      }
      default:
        return null
    }
  }

  const buttonProps: CardProps = {
    button,
    handleClick,
    buttonAction,
    startWorkflows,
    radius,
  }
  switch (button.style) {
    //Traditional Button - Contained (0) / Outline (1)
    case 0:
    case 1: {
      return <SimpleButton {...buttonProps} />
    }
    //Button with gradient background
    case 2: {
      return <GradientCard {...buttonProps} />
    }
    //Button with background image
    case 3: {
      return <BackgroundImageCard {...buttonProps} />
    }
    //Large Button with image
    case 4: {
      return <StandardLargeCard {...buttonProps} />
    }
    //Medium Button with image
    case 5: {
      return <StandardMediumCard {...buttonProps} />
    }
    //Carousel Card
    case 6:
    case 7: {
      return <CarouselCard {...buttonProps} />
    }
    //Transparent Card
    case 8: {
      return <TransparentCard {...buttonProps} />
    }
    //Benefit Card
    case 9: {
      return <BenefitCard {...buttonProps} />
    }
    //Passport Card
    case 10: {
      return <PassportCard {...buttonProps} />
    }
    //Test History Card
    case 11: {
      return <TestHistoryCard />
    }
    default: {
      return <></>
    }
  }
}
