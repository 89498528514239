import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { MenuOption } from '../../../../pages/settings/type'

export const ActionCell = ({
  menuItems,
  data,
}: {
  onClick?: () => void
  data: any
  menuItems?: MenuOption[]
}) => {
  const [anchorElement, setAnchorElement] = useState<Element | null>(null)

  const handleOpenMenu = (currentTarget: Element) => {
    setAnchorElement(currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorElement(null)
  }

  return (
    <>
      <IconButton onClick={(e) => handleOpenMenu(e.currentTarget)} size="large">
        <MoreHorizIcon />
      </IconButton>
      {menuItems?.length && (
        <Menu
          anchorEl={anchorElement}
          open={Boolean(anchorElement)}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {menuItems.map((item) => (
            <MenuItem
              key={item.label}
              onClick={(e) => item.onClick(data, e)}
              disabled={item.disabled || item.isLoading}
            >
              <Typography variant="inherit">{item.label}</Typography>
              {item.isLoading && (
                <CircularProgress size={14} style={{ marginLeft: 5 }} />
              )}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}
