import CloseIcon from '@mui/icons-material/Close'
import { Icon } from '@mui/material'
import { ClassNameMap } from '@mui/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as IosShareIcon } from '../../assets/icons/iOS-share.svg'
import SafeLogo from '../../assets/Logo-Dark2x.png'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { useAgent, UserBrowser, UserDevice } from '../../libs/hooks/useAgent'
import { Typography } from '../../ui'
import { onboardingStyles } from '../_styles/registrationStyles'

export const InstallationBanner = (props: {
  closeInstallBanner: () => void
}) => {
  const { closeInstallBanner } = props
  const classes = onboardingStyles()
  const i18n = useSelector(selectLanguageContent)
  const { device, browser } = useAgent()

  const getInstallMessage = () => {
    switch (browser) {
      case UserBrowser.safari: {
        return i18n.add_to_homescreen_ios_description
      }
      case UserBrowser.chrome: {
        return i18n.add_to_homescreen_chrome_description
      }
      default: {
        return ''
      }
    }
  }

  return (
    <div className={classes.stepContent}>
      <div className={classes.installBannerWrapper}>
        <div className={classes.installBannerContent}>
          <CloseIcon
            className={classes.installCloseIcon}
            onClick={() => closeInstallBanner()}
          />
          <img src={SafeLogo} height={80} />
          <Typography align="center" className={classes.installTitle}>
            {device === UserDevice.iOS || device === UserDevice.Android
              ? i18n.add_to_homescreen_title
              : i18n.install_desktop_title}
          </Typography>
          <Typography align="center" className={classes.installDescription}>
            {getInstallMessage()}
          </Typography>
          <InstallInstructions
            i18n={i18n}
            classes={classes}
            isNative={
              device === UserDevice.iOS || device === UserDevice.Android
            }
            browser={browser}
          />
        </div>
      </div>
    </div>
  )
}

const InstallInstructions = (props: {
  i18n: I18n
  classes: ClassNameMap
  isNative: boolean
  browser: UserBrowser | undefined
}) => {
  const { i18n, classes, isNative, browser } = props

  const getMessage = () => {
    switch (browser) {
      case UserBrowser.chrome: {
        if (isNative) {
          return i18n.add_to_homescreen_instructions.split('@icon')
        }
        return i18n.install_desktop_chrome_instructions.split('@icon')
      }
      case UserBrowser.safari: {
        if (isNative) {
          return i18n.add_to_homescreen_instructions.split('@icon')
        }
        return i18n.install_desktop_safari_instructions.split('@icon')
      }
      default:
        return i18n.add_to_homescreen_instructions.split('@icon')
    }
  }

  const inst = getMessage()

  return (
    <div style={{ display: 'flex', gap: 4 }}>
      {inst[0] && (
        <Typography className={classes.installInstruction}>
          {inst[0]}
        </Typography>
      )}
      {!isNative && browser === UserBrowser.chrome ? (
        <Icon className={classes.installIcon}>browser_updated</Icon>
      ) : (
        <IosShareIcon />
      )}

      {inst[1] && (
        <Typography className={classes.installInstruction}>
          {inst[1]}
        </Typography>
      )}
    </div>
  )
}
