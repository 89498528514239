import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import HeadsetMicRoundedIcon from '@mui/icons-material/HeadsetMicRounded'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded'
import HomeIcon from '@mui/icons-material/Home'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import PeopleIcon from '@mui/icons-material/People'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import PhoneIcon from '@mui/icons-material/Phone'
import PolicyRoundedIcon from '@mui/icons-material/PolicyRounded'
import SecurityIcon from '@mui/icons-material/Security'
import SettingsIcon from '@mui/icons-material/Settings'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded'
import { Icon } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as CheckListIcon } from '../../../assets/icons/check-list.svg'
import { ReactComponent as LogoutIcon } from '../../../assets/icons/LogoutIcon.svg'
import { ReactComponent as MedicalServicesIcon } from '../../../assets/icons/medical-services.svg'
import { ReactComponent as MenuBook } from '../../../assets/icons/menu-book.svg'
import { ReactComponent as PasswordIcon } from '../../../assets/icons/password.svg'
import { ReactComponent as RateReview } from '../../../assets/icons/rate-review.svg'
import { ReactComponent as RegisterQR } from '../../../assets/icons/register-qr.svg'
import { ReactComponent as TaskAlt } from '../../../assets/icons/task-alt.svg'
import { ReactComponent as Topic } from '../../../assets/icons/topic-rounded.svg'
import { ReactComponent as Vaccinations } from '../../../assets/icons/vaccinations-icon.svg'
import { ReactComponent as VideoLibrary } from '../../../assets/icons/video-library.svg'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { lsClient } from '../../../ls-client'
import { selectNetworkSettings } from '../../../pages/network/model'
import { paths } from '../../../pages/paths'
import { selectHasDependents } from '../../../pages/profile/model'
import { resetRegisterForm } from '../../../pages/register/model/registerSlice'
import { setActive } from '../../../pages/register-a-test-module/model'
import { RegisterTestStepType } from '../../../pages/register-a-test-module/types'
import {
  AccountSettingsType,
  SecuritySettingsType,
  SettingsTabType,
} from '../../../pages/settings/type'
import { Drawer } from '../../../ui'
import { useDialog } from '../../dialog/application-dialog'
import { selectHealthServiceList } from '../../health-service-lite/model'
import { StoreStepType } from '../../shopify/model/types'
import { selectLanguageContent } from '../../translation'
import { DrawerHeader } from '../../user'
import { logoutUser } from '../../user/model/userTenantSlice'
import { DrawerListItem } from '../atoms'
import { drawerClosed, selectIsDrawerOpen } from '../model'

export const ApplicationDrawer = ({
  hideWhenClosed,
  isDesktop,
  isSmallDesktop,
}: {
  hideWhenClosed?: boolean
  isDesktop: boolean
  isSmallDesktop: boolean
}) => {
  const isOpen = useSelector(selectIsDrawerOpen)
  const i18n = useSelector(selectLanguageContent)
  const navigate = useNavigate()
  const networkSettings = useSelector(selectNetworkSettings)
  const dispatch = useDispatch()
  const hasDependents = useSelector(selectHasDependents)
  const { isMobile } = useMUITheme()
  const { openDialog, closeDialog } = useDialog()
  const healthServiceList = useSelector(selectHealthServiceList)

  const hasHealthServiceActive =
    Boolean(healthServiceList[0]) && healthServiceList[0].status !== 'Completed'

  const handleDrawerClose = () => {
    dispatch(drawerClosed())
  }

  const handleLogout = () => {
    dispatch(resetRegisterForm())
    localStorage.removeItem('email')
    lsClient.clearCurrentUserLS()
    closeDialog()
    navigate(paths.login())
    dispatch(logoutUser())
  }

  const openLogoutDialog = () => {
    const content = {
      title: i18n.logout_title,
      body: i18n.logout_description,
      confirmationLabel: i18n.log_out,
      onConfirm: handleLogout,
      cancelLabel: i18n.cancel_button,
      onCancel: closeDialog,
    }
    openDialog(content)
  }

  // const HealthWorkerItem = () => {
  //   const userPermission = useSelector(selectUserPermission)
  //   return (
  //     <DrawerListItem
  //       path={paths.app.scanOrderCode()}
  //       icon={<CropFreeIcon />}
  //       label={i18n.health_worker}
  //       isVisible={userPermission === 'CHECKER'}
  //     />
  //   )
  // }

  const DependentsItem = () => (
    <DrawerListItem
      path={paths.dependents()}
      icon={<PeopleIcon />}
      label={i18n.dependents}
      fullSizeOnDesktop={false}
      isVisible={hasDependents}
    />
  )

  const CarePlanItem = () => (
    <DrawerListItem
      path={paths.treatmentPlanHistory()}
      icon={<HistoryRoundedIcon />}
      label={i18n.treatment_plans}
      fullSizeOnDesktop={false}
      isVisible={Boolean(networkSettings?.telehealthConfiguration)}
    />
  )

  const OrderHistoryItem = () => (
    <DrawerListItem
      path={paths.shopifyStore(StoreStepType.ORDER_HISTORY)}
      icon={<ShoppingCartRoundedIcon />}
      label={'Order History'}
      fullSizeOnDesktop={false}
    />
  )

  // const VaccinationsItem = () => (
  //   <DrawerListItem
  //     // path={paths.app.getTested()}
  //     icon={<Vaccinations style={{ marginLeft: 4 }} />}
  //     label={'Vaccinations'}
  //     isVisible={
  //       networkSettings?.verifyVaccinationSettings.enableVaccinationRecord
  //     }
  //   />
  // )

  const onGetTested = () => {
    if (networkSettings?.enableChooseATestScreen) {
      dispatch(setActive(1))
      navigate(paths.registerTest(RegisterTestStepType.CHOOSE_A_TEST))
    } else {
      lsClient.navigation.setBackPath('/app/dashboard')
      dispatch(setActive(3))
      navigate(paths.registerTest(RegisterTestStepType.SCAN_CODE))
    }
  }

  const GetTestedItem = () => (
    <DrawerListItem
      onClick={onGetTested}
      icon={<AssignmentTurnedInIcon />}
      label={i18n.get_tested}
      isVisible={networkSettings?.menuItems.enableTakeATest}
      fullSizeOnDesktop={true}
    />
  )

  // const ImportHealthRecordsItem = () => {
  //   return (
  //     <DrawerListItem
  //       icon={<ShoppingCartRoundedIcon />}
  //       label={i18n.import_health_records}
  //     />
  //   )
  // }

  const TestResultsItem = () => (
    <DrawerListItem
      icon={<CheckListIcon />}
      path={paths.testResults()}
      label={i18n.test_history_screen_title}
      fullSizeOnDesktop={false}
    />
  )

  const ResourcesItem = () => (
    <DrawerListItem
      icon={<VideoLibrary />}
      path={paths.resources()}
      label={i18n.resources}
      fullSizeOnDesktop={true}
    />
  )

  const FaqItem = () => (
    <DrawerListItem
      path={paths.faqs()}
      icon={<HelpRoundedIcon />}
      label={i18n.help_center_settings}
      fullSizeOnDesktop={false}
    />
  )

  const ContactSupportItem = () => (
    <DrawerListItem
      path={paths.contactSupport()}
      icon={<RateReview />}
      label={i18n.contact_support}
      fullSizeOnDesktop={false}
    />
  )

  const LegalItem = () => (
    <DrawerListItem
      path={paths.termsAndConds()}
      icon={<MenuBook />}
      label={i18n.terms_and_conditions_link}
      fullSizeOnDesktop={false}
    />
  )

  const HealthRecordItem = () => (
    <DrawerListItem
      onClick={() => navigate(paths.healthRecordList())}
      icon={<Icon>monitor_heart</Icon>}
      label={i18n.health_record}
      fullSizeOnDesktop={false}
    />
  )

  const SettingsItem = () => (
    <DrawerListItem
      path={paths.settingsTab('account', 'personal-info')}
      icon={<SettingsIcon />}
      label={i18n.settings}
      fullSizeOnDesktop={false}
    />
  )

  const LogoutItem = () => (
    <DrawerListItem
      onClick={openLogoutDialog}
      icon={<LogoutIcon />}
      label={i18n.log_out}
      fullSizeOnDesktop={true}
    />
  )

  const GetCareNowItem = () => (
    <DrawerListItem
      onClick={() => navigate(paths.flowList())}
      icon={<MedicalServicesIcon />}
      label={i18n.begin_health_service}
      isVisible={!hasHealthServiceActive}
      fullSizeOnDesktop={true}
    />
  )

  const OrderTestItem = () => (
    <DrawerListItem
      onClick={() => navigate(paths.shopifyStore(StoreStepType.PRODUCTS))}
      icon={<ShoppingBasketIcon />}
      label={i18n.shop}
      fullSizeOnDesktop={true}
    />
  )

  const PrimaryAddressItem = () => (
    <DrawerListItem
      onClick={() =>
        navigate(
          paths.settingsTab(
            SettingsTabType.ACCOUNT,
            AccountSettingsType.MY_ADDRESSES
          )
        )
      }
      icon={<HomeIcon />}
      label={'Primary Address'}
      fullSizeOnDesktop={false}
    />
  )

  const PasswordItem = () => (
    <DrawerListItem
      onClick={() =>
        navigate(
          paths.settingsTab(
            SettingsTabType.SECURITY,
            SecuritySettingsType.PASSWORD
          )
        )
      }
      icon={<PasswordIcon />}
      label={i18n.login_password}
      fullSizeOnDesktop={false}
    />
  )

  const EditProfileItem = () => (
    <DrawerListItem
      onClick={() =>
        navigate(
          paths.settingsTab(
            SettingsTabType.ACCOUNT,
            AccountSettingsType.PERSONAL_INFORMATION
          )
        )
      }
      icon={<AccountCircleIcon />}
      label={i18n.edit_profile_settings}
      fullSizeOnDesktop={false}
    />
  )

  const EmergencyContactItem = () => (
    <DrawerListItem
      onClick={() =>
        navigate(
          paths.settingsTab(
            SettingsTabType.ACCOUNT,
            AccountSettingsType.EMERGENCY_CONTACT
          )
        )
      }
      icon={<ContactPhoneIcon />}
      label={i18n.emergency_contact_settings}
      fullSizeOnDesktop={false}
    />
  )

  const MobileContactItem = () => (
    <DrawerListItem
      onClick={() =>
        navigate(
          paths.settingsTab(
            SettingsTabType.ACCOUNT,
            AccountSettingsType.MOBILE_NUMBER
          )
        )
      }
      icon={<PhoneIcon />}
      label={i18n.mobile_number_tab}
      fullSizeOnDesktop={false}
    />
  )

  const PaymentMethodsItem = () => (
    <DrawerListItem
      onClick={() =>
        navigate(
          paths.settingsTab(
            SettingsTabType.ACCOUNT,
            AccountSettingsType.PAYMENT_METHODS
          )
        )
      }
      icon={<CreditCardIcon />}
      label={i18n.payment_method_settings}
      fullSizeOnDesktop={false}
    />
  )

  const RegisterTestItem = () => (
    <DrawerListItem
      onClick={() => navigate(paths.registerTest(RegisterTestStepType.INTRO))}
      icon={<RegisterQR />}
      label={'Register a test'}
      fullSizeOnDesktop={false}
    />
  )

  const generalGroup = {
    title: '',
    id: 'general',
    //Demo
    items: [GetCareNowItem, OrderTestItem, ResourcesItem],
    // items: [GetCareNowItem, GetTestedItem, OrderTestItem, ResourcesItem],
    icon: null,
    accordionOnDesktop: false,
  }

  const logoutGroup = {
    title: '',
    id: 'logout',
    items: [LogoutItem],
    icon: null,
    accordionOnDesktop: false,
  }

  const recordsGroup = {
    title: 'Records',
    id: 'Records',
    //Demo
    items: [CarePlanItem, OrderHistoryItem, HealthRecordItem],
    // items: [CarePlanItem, OrderHistoryItem, HealthRecordItem, TestResultsItem],
    icon: <Topic />,
    accordionOnDesktop: true,
  }

  const supportGroup = {
    title: 'Support',
    id: 'Support',
    items: [FaqItem, ContactSupportItem],
    icon: <HeadsetMicRoundedIcon />,
    accordionOnDesktop: true,
  }

  // const securityGroup = {
  //   title: 'Security',
  //   id: 'Security',
  //   items: [PasswordItem],
  //   icon: <SecurityIcon />,
  //   accordionOnDesktop: true,
  // }

  const settingsGroup = {
    title: 'Settings',
    id: 'Settings',
    items: [
      EditProfileItem,
      DependentsItem,
      PasswordItem,
      MobileContactItem,
      PaymentMethodsItem,
      PrimaryAddressItem,
      EmergencyContactItem,
    ],
    icon: <SettingsIcon />,
    accordionOnDesktop: true,
  }

  const legalGroup = {
    title: 'Legal',
    id: 'Legal',
    items: [LegalItem],
    icon: <PeopleAltRoundedIcon />,
    accordionOnDesktop: true,
  }

  return (
    <Drawer
      anchor={isMobile ? 'right' : 'left'}
      isOpen={isDesktop ? true : isOpen}
      isDesktop={isDesktop}
      onDrawerClose={handleDrawerClose}
      drawerHeader={<DrawerHeader isOpen={isDesktop ? true : isOpen} />}
      drawerItems={[
        generalGroup,
        logoutGroup,
        recordsGroup,
        supportGroup,
        // securityGroup,
        settingsGroup,
        legalGroup,
      ]}
    />
  )
}
