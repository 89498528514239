import { makeStyles } from '@mui/styles'

export const telehealthStyles = makeStyles((theme) => ({
  mt1: {
    marginTop: '32px',
  },
  telehealthWrapper: {},
  providerWrapper: {
    width: '600px',
  },
  interruption: {
    height: '75vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(600)]: {
      height: 'calc(100vh - 135px)',
      overflow: 'hidden',
    },
  },
  closeIcon: {
    [theme.breakpoints.down(600)]: {
      position: 'absolute',
      right: 16,
    },
  },
  wrapper: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box',
    alignItems: 'center',
  },
  textContentContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '24px',
  },
  loadingContainer: {
    margin: '0 auto',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    maxWidth: '600px',
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    maxWidth: 540,
    margin: '0 auto',
    textAlign: 'center',
  },
  pageWrapper: {
    width: '90vw',
    maxWidth: '342px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down(600)]: {
      minHeight: 'calc(100vh - 320px)',
    },
  },
  processText: {
    fontSize: 26,
    color: '#FFF',
    marginTop: 10,
  },
  animationContainer: {
    margin: '20px auto 0px',
    maxWidth: '405px',
  },
  altAnimationBG: {
    [theme.breakpoints.up(600)]: {
      borderRadius: '8px',
      margin: '32px auto',
    },
  },
  title: {
    width: '100%',
    fontWeight: 500,
    lineHeight: '55px',
    fontSize: 32,
    textAlign: 'center',
    [theme.breakpoints.down(389)]: {
      width: '90%',
      lineHeight: '36px',
    },
  },
  body: {
    width: '100%',
    fontWeight: 500,
    marginTop: 10,
    lineHeight: '22px',
    fontSize: 16,
    textAlign: 'center',
    marginBottom: '16px',
    color: '#898989',
    [theme.breakpoints.down(600)]: {
      width: '90%',
    },
    [theme.breakpoints.down(389)]: {
      fontSize: 14,
      marginBottom: '10px',
      lineHeight: '20px',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  header: {
    padding: 0,
    borderBottom: '1px solid #D7D7D7',
  },
  buttonWrapper: {
    marginTop: '30px',
    width: '100%',
    paddingBottom: '32px',
    maxWidth: '600px',
    [theme.breakpoints.down(960)]: {
      position: 'relative',
      bottom: '16px',
      zIndex: 1001,
      padding: '0px',
    },
    [theme.breakpoints.down(600)]: {
      backgroundColor: '#FFF',
      width: '90vw',
      position: 'fixed',
      bottom: '0px',
      padding: '16px 0px 32px',
      zIndex: 1001,
    },
  },
  altBG: {
    [theme.breakpoints.down(600)]: {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
  whiteText: {
    color: '#FFF !important',
  },
  incomingPhoneNumber: {
    fontWeight: 500,
    fontSize: '24px',
    color: '#898989',
  },
  skip: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    [theme.breakpoints.down(960)]: {
      width: '90vw',
      marginTop: '16px',
    },
    [theme.breakpoints.down(600)]: {
      marginTop: '0px',
    },
  },
  ava: {
    width: '100%',
    height: '100%',
    minHeight: '150px',
  },
  experienceHeader: {
    fontSize: '32px',
    margin: '32px 0px',
    [theme.breakpoints.down(600)]: {
      fontSize: '24px',
    },
  },
  ratingContainer: {
    width: '100%',
    minHeight: '54px',
    margin: '0px auto',
    padding: '32px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px dotted #E0E0E0',
    [theme.breakpoints.down(960)]: {
      width: '85%',
      padding: '8px 0px',
    },
  },
  star: {
    fontSize: '50px',
    color: theme.palette.primary.main,
  },
  starOutline: {
    margin: '8px',
  },
  addComment: {
    margin: '16px 0px',
    width: '400px',
    display: 'flex',
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
  },
  addCommentContainer: {
    margin: '16px 0px',
    minHeight: '150px',
    width: '400px',
    display: 'flex',
    [theme.breakpoints.down(600)]: {
      width: '100%',
      margin: '0px',
      paddingBottom: '100px',
    },
  },
  imageOutline: {
    padding: '2px',
    border: '2px solid #E0E0E0',
    borderRadius: '100%',
    maxWidth: '150px',
    minHeight: '150px',
  },
  form: {
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '100%',
    boxSizing: 'border-box',
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  checkbox: {
    color: '#003C71',
    padding: 0,
  },
  termsCheckboxContainer: {
    marginTop: '24px',
    display: 'flex',
    alignItems: 'center',
  },
  terms: {
    marginLeft: '-8px',
    color: '#7E7E7E',
  },
  termsLink: {
    cursor: 'pointer',
    fontWeight: 600,
  },
  actionBtn: {
    'fontSize': '16px',
    'textTransform': 'none',
    'letterSpacing': '2.25px',
    'maxWidth': 'unset',
    'width': '100%',
    'justifySelf': 'center',
    'maxHeight': 48,
    'paddingTop': 10,
    'paddingBottom': 10,
    'marginTop': 10,
    'marginBottom': 0,
    '@media (max-width: 300px)': {
      minWidth: 'unset',
    },
  },
  timerBox: {
    height: '133px',
    width: '162px',
    border: '3px #E0E0E0 solid',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  countdownTimer: {
    fontWeight: 800,
    [theme.breakpoints.up(600)]: {
      fontSize: '32px',
    },
  },
  countdownText: {
    marginTop: '8px',
    fontSize: '14px',
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    margin: '16px 0px 24px',
    [theme.breakpoints.down(600)]: {
      margin: '-24px 0px 38px',
    },
  },
  mdName: {
    fontSize: '18px',
    margin: '8px',
  },
  closeIconWrapper: {
    position: 'absolute',
    right: 15,
    cursor: 'pointer',
  },
  backIcon: {
    cursor: 'pointer',
    marginRight: '48px',
    color: '#505358',
    [theme.breakpoints.down(600)]: {
      marginRight: '8px',
    },
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 500,
    [theme.breakpoints.down(600)]: {
      fontWeight: 400,
      fontSize: 16,
    },
  },
  videoPreviewWrapper: {
    position: 'absolute',
    right: '10%',
    top: 290,
    [theme.breakpoints.down(1280)]: {
      right: '5%',
    },
    [theme.breakpoints.down(600)]: {
      right: '5%',
      top: '550px',
    },
  },
  videoSession: {
    'borderRadius': '8px',
    '-webkit-mask-image':
      '-webkit-radial-gradient(circle, white 100%, black 100%)',
  },
  lgButtonBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(576)]: {
      flexDirection: 'column',
    },
  },
  confirmButton: {
    minWidth: 'unset',
    maxWidth: 'fit-content',
    padding: '10px 24px',
    width: '100%',
    height: '46px',
    [theme.breakpoints.down(576)]: {
      margin: '14px auto',
      minWidth: '250px',
    },
  },
  schedulePageHolder: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F7F7F7',
    width: 571,
    height: 279,
    [theme.breakpoints.down(560)]: {
      padding: '0px',
      width: 'unset',
      height: 'unset',
    },
  },
  scheduleWrapper: {
    width: '100%',
    margin: '40px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    [theme.breakpoints.down(500)]: {
      margin: '24px',
    },
  },
  intakeHeader: {
    maxWidth: '500px',
    display: 'flex',
    alignItems: 'center',
    margin: '0px auto',
    paddingBottom: 8,
    paddingTop: 16,
    paddingRight: 54,
  },
  arrowBack: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginLeft: 24,
    transform: 'rotate(45deg)',
  },
  root: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '500px',
    [theme.breakpoints.down(600)]: {
      width: '90vw',
      maxWidth: 'unset',
    },
  },
  dialogHeader: {
    maxWidth: '500px',
    display: 'flex',
    paddingBottom: 8,
    paddingTop: 16,
    [theme.breakpoints.down(600)]: {
      marginLeft: '26px',
    },
  },
  messageContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '32px',
    justifyContent: 'space-between',
    [theme.breakpoints.down(389)]: {
      marginTop: '24px',
    },
  },
  mediumBodyText: {
    fontWeight: 500,
    marginBottom: '24px',
    textAlign: 'center',
    [theme.breakpoints.down(600)]: {
      marginBottom: '16px',
      width: '100%',
    },
    [theme.breakpoints.down(389)]: {
      fontSize: 14,
      marginBottom: '10px',
      lineHeight: '20px',
    },
  },
  locationTitle: {
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.primary.main,
    fontFamily: 'inherit',
    textAlign: 'inherit',
    [theme.breakpoints.down(600)]: {
      width: '90%',
    },
    [theme.breakpoints.down(389)]: {
      width: '90%',
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  locationBody: {
    width: '100%',
    fontWeight: 400,
    lineHeight: '16px',
    fontSize: 12,
    textAlign: 'inherit',
    fontFamily: 'inherit',
  },
  loadingOverlay: {
    transform: 'scale(.50)',
  },
  button: {
    width: '100%',
    maxWidth: '100%',
    maxHeight: 45,
    margin: '32px auto',
    [theme.breakpoints.down(600)]: {
      position: 'absolute',
      width: '90vw',
      bottom: 0,
    },
  },
  entries: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  selectElm: {
    width: '100%',
  },
  locationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  dialogWrapper: {
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#FFF',
  },
  dialogContent: {
    width: '500px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    gap: '32px',
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
  },
  formWrapper: {
    [theme.breakpoints.down(600)]: {
      width: '90vw',
      margin: '0px',
    },
  },
  useCurrentContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '24px',
    cursor: 'pointer',
  },
  backButton: {
    alignSelf: 'flex-start',
    height: 42,
    width: 42,
    marginTop: '10px',
    [theme.breakpoints.down(300)]: {
      height: 24,
      width: 24,
    },
  },
  edit: {
    alignSelf: 'flex-start',
    marginBottom: '12px',
  },
  container: {
    width: '342px',
    padding: '24px',
    margin: 8,
    border: '1px solid #E0E0E0',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '8px',
    [theme.breakpoints.down(600)]: {
      width: 'calc(100% - 48px)',
    },
    [theme.breakpoints.down(389)]: {
      padding: '12px',
    },
  },
  editLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    borderBottom: '1px solid',
    alignSelf: 'flex-end',
    marginLeft: '4px',
    marginBottom: '3px',
    borderBottomColor: theme.palette.primary.main,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  phoneContainer: {
    display: 'flex',
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))
