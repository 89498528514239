import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp'
import { Box, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { shopifyStoreStyles } from '../../../pages/_styles/shopifyStoreStyles'
import { I18n } from '../../translation/types'

export const OrderHistoryItem = (props: {
  order: any
  handleNext: (testId: string) => void
  i18n: I18n
}) => {
  const { order, handleNext, i18n } = props
  const classes = shopifyStoreStyles()

  const { isMobile } = useMUITheme()

  const getPlural = (q: number) => {
    if (q === 1) {
      return `${q} ${i18n['ecommerce.item_text.singular']}`
    }
    return `${q} ${i18n['ecommerce.item_text.plural']}`
  }
  if (isMobile) {
    return (
      <Box
        className={classes.listItemBox}
        onClick={() => handleNext(order.identifier)}
      >
        <div className={classes.summaryLineItem}>
          <div className={classes.mobileContent}>
            <Typography className={`${classes.purchaseLineItem} date`}>{`${
              order.orderDateString &&
              format(new Date(order.orderDateString), 'MMM. d, y')
            }`}</Typography>
            <div className={classes.resultWrapperMobile}>
              <Typography className={`${classes.purchaseLineItem} item`}>
                {`${order.total.currencySymbol}${
                  typeof order.total.amountDouble === 'number' &&
                  order.total.amountDouble.toString().includes('.')
                    ? order.total.amountDouble
                    : `${order.total.amountDouble}.00`
                }`}
              </Typography>
              <div
                style={{
                  height: 21,
                  borderRight: 'solid 1px #757575',
                }}
              />
              <Typography className={`${classes.purchaseLineItem} item`}>
                {getPlural(order.quantity)}
              </Typography>
            </div>
          </div>
          <div className={`${classes.historyItemWrapper} images`}>
            <div className={classes.orderImageListWrapper}>
              {order.imageUrls && order.imageUrls.length > 0 ? (
                order.imageUrls.map((image: string, i: number) => {
                  if (i > 2) return null
                  if (i === 2 && order.imageUrls.length > 3) {
                    return (
                      <div key={image} className={classes.historyImageWrapper}>
                        <Typography
                          align="center"
                          style={{ width: '100%' }}
                          className={`${classes.summaryLineText}`}
                        >{`+${order.imageUrls.length - i}`}</Typography>
                      </div>
                    )
                  }
                  return (
                    <div key={image} className={classes.historyImageWrapper}>
                      <div
                        className={classes.imageContainer}
                        style={{
                          backgroundImage: `url(${image})`,
                        }}
                      />
                    </div>
                  )
                })
              ) : (
                <div style={{ width: 64, height: 64 }} />
              )}
            </div>
          </div>
        </div>
        <ChevronRightSharpIcon className={classes.chevronIcon} />
      </Box>
    )
  }
  return (
    <Box
      className={classes.listItemBox}
      onClick={() => handleNext(order.identifier)}
    >
      <div className={classes.historyItemWrapper}>
        <Typography className={classes.purchaseLineItem}>{`${
          order.orderDateString &&
          format(new Date(order.orderDateString), 'MMM. d, y')
        }`}</Typography>
      </div>
      <div style={{ marginLeft: '5%' }} className={classes.historyItemWrapper}>
        <Typography className={classes.date}>
          {getPlural(order.quantity)}
        </Typography>
      </div>
      <div className={classes.historyItemWrapper}>
        <Typography className={classes.date}>
          {`${order.total.currencySymbol}${
            typeof order.total.amountDouble === 'number' &&
            order.total.amountDouble.toString().includes('.')
              ? order.total.amountDouble
              : `${order.total.amountDouble}.00`
          }`}
        </Typography>
      </div>
      <div className={`${classes.historyItemWrapper} images`}>
        <div className={classes.orderImageListWrapper}>
          {order.imageUrls && order.imageUrls.length > 0 ? (
            order.imageUrls.map((image: string, i: number) => {
              if (i > 2) return null
              if (i === 2 && order.imageUrls.length > 3) {
                return (
                  <div key={image} className={classes.historyImageWrapper}>
                    <Typography
                      align="center"
                      style={{ width: '100%' }}
                      className={`${classes.summaryLineText}`}
                    >{`+${order.imageUrls.length - i}`}</Typography>
                  </div>
                )
              }
              return (
                <div key={image} className={classes.historyImageWrapper}>
                  <div
                    className={classes.imageContainer}
                    style={{
                      backgroundImage: `url(${image})`,
                    }}
                  />
                </div>
              )
            })
          ) : (
            <div style={{ width: 64, height: 64 }} />
          )}
        </div>
      </div>
      <ChevronRightSharpIcon className={classes.chevronIcon} />
    </Box>
  )
}
