import styled from '@emotion/styled'
import { ListItem, ListItemIcon } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { hexToRGBA } from '../../../libs/helpers'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { Typography } from '../../../ui'

export const DrawerListItem = ({
  path,
  icon,
  label,
  isVisible = true,
  onClick,
  fullSizeOnDesktop,
}: {
  path?: string
  onClick?: () => void
  icon: JSX.Element
  label: string
  fullSizeOnDesktop: boolean
  isVisible?: boolean
}) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { isMobile } = useMUITheme({ breakpoint: 1200 })
  const handleClick = () => {
    if (onClick) onClick()
    if (path) navigate(path)
  }

  return (
    <ListItemStyled
      // button
      onClick={handleClick}
      data-is-visible={isVisible}
      data-remove-borders={!isMobile}
      className={classes.root}
    >
      {(isMobile || fullSizeOnDesktop) && (
        <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      )}
      <div className={classes.titleWapper}>
        <Typography
          className={
            !isMobile && fullSizeOnDesktop
              ? `${classes.label} logoutItem`
              : classes.label
          }
        >
          {label}
        </Typography>
        {isMobile && <ChevronRightIcon className={classes.icon} />}
      </div>
    </ListItemStyled>
  )
}

const ListItemStyled = styled(ListItem)`
  padding: 16px 8px 16px 24px;
  margin-top: -1px;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  &[data-remove-borders='true'] {
    border-bottom: none;
    border-top: none;
  }
  &[data-is-visible='false'] {
    display: none;
  }
  @media (max-width: 1200px) {
    padding: 16px 8px 16px 0px;
  }
`
const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    [theme.breakpoints.up(960)]: {
      '&:hover': {
        backgroundColor: hexToRGBA(theme.palette.primary.main, 0.05),
      },
    },
  },
  label: {
    'fontSize': 16,
    'fontWeight': 600,
    'color': '#505358',
    'whiteSpace': 'nowrap',
    '&.logoutItem': {
      fontSize: 20,
      fontWeight: 500,
      marginLeft: 24,
      color: '#282D37',
    },
  },
  icon: {
    minWidth: 40,
    color: '#757575',
  },
  titleWapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))
