/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Dialog, styled } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLanguageContent } from '../../features/translation'
import { isAppRunningInstalled } from '../../libs/helpers'
import { useMUITheme } from '../../libs/hooks/useTheme'
import { Observer } from '../../libs/observer'
import {
  HTMLTextComponent,
  NativeButtonWrapper,
} from '../../libs/shared-components'
import { getColor } from '../../libs/utils'
import { Button, PageLoading, Typography } from '../../ui'
import { getQuestionnaireColors } from './outcomes/helpers/utils'
import {
  nextQuestionV2,
  selectAutoSubmit,
  selectCanContinue,
  selectCurrentDescription,
  selectCurrentQuestion,
  selectExistingAnswer,
  selectQuestionLoading,
  selectQuestionnaireColor,
  selectQuestionnaireTitle,
  setCanContiune,
} from './questionnaireV2Slice'
import {
  DateQuestion,
  HeightQuestion,
  LargeTextQuestion,
  MultipleQuestion,
  NumericQuestion,
  ScaleQuestion,
  SliderQuestion,
  SmallTextQuestion,
  YesNoQuestion,
} from './questions'
import { QuestionnaireColorType, QuestionType } from './types'

const questionTypes: Record<QuestionType, React.ReactNode> = {
  [QuestionType.Multiple]: <MultipleQuestion />,
  [QuestionType.Numeric]: <NumericQuestion />,
  [QuestionType.Date]: <DateQuestion />,
  [QuestionType.Scale]: <ScaleQuestion />,
  [QuestionType.Height]: <HeightQuestion />,
  [QuestionType.LargeText]: <LargeTextQuestion />,
  [QuestionType.SmallText]: <SmallTextQuestion />,
  [QuestionType.YesNo]: <YesNoQuestion />,
  [QuestionType.Slider]: <SliderQuestion />,
}

export const QuestionnaireExecution = () => {
  const dispatch = useDispatch()
  const questionDescription = useSelector(selectCurrentDescription)
  const questionnaireTitle = useSelector(selectQuestionnaireTitle)
  const autoSubmit = useSelector(selectAutoSubmit)
  const question = useSelector(selectCurrentQuestion)
  const isLoading = useSelector(selectQuestionLoading)
  const canContinue = useSelector(selectCanContinue)
  const existingAnswer = useSelector(selectExistingAnswer)
  const questionnaireColor = useSelector(selectQuestionnaireColor)
  const color = getQuestionnaireColors(questionnaireColor) || 'secondary'
  const classes = useStyles({ color })
  const [overFlowIndicator, setOverFlowIndicator] = useState(true)
  const [contentInView, setContentInView] = useState(false)
  const i18n = useSelector(selectLanguageContent)
  const [helpDialogOpen, setHelpDialogOpen] = useState(false)
  const isAutoSubmitQuestion =
    (question.type === QuestionType.YesNo && !existingAnswer) ||
    (question.type === QuestionType.Multiple &&
      !existingAnswer &&
      question.multiSelect === false)
  const { isMobile } = useMUITheme()
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    dispatch(nextQuestionV2())
  }
  const { theme } = useMUITheme()

  const questionnaireThemeColor = getColor(color, theme)
  const appRunningInstalled = isAppRunningInstalled()

  useEffect(() => {
    if (!existingAnswer && question) {
      dispatch(setCanContiune(false))
      setOverFlowIndicator(true)
    }
  }, [question, existingAnswer])

  useEffect(() => {
    setContentInView(false)
  }, [question])

  const observe = () => {
    setContentInView(true)
    setOverFlowIndicator(false)
  }

  useEffect(() => {
    if (autoSubmit)
      setTimeout(() => {
        dispatch(nextQuestionV2())
      }, 100)
  }, [autoSubmit])

  if (isLoading) return <PageLoading size={100} appColor={color} />

  return (
    <>
      {question.image && (
        <div className={classes.questionImageWrapper}>
          <img src={question.image} className={classes.questionImage} />
        </div>
      )}
      <form className={classes.form} onSubmit={handleSubmit}>
        <Box className={classes.questionWrapper}>
          <Box className={classes.questionContent}>
            <PageWrapper>
              <Typography className={classes.questionnaireName}>
                {questionnaireTitle}
              </Typography>
              <Typography className={classes.question}>
                {question?.name?.translations.eng}
              </Typography>
              {question.help?.translations?.eng && (
                <Box
                  style={{
                    color: questionnaireThemeColor,
                  }}
                  className={classes.infoWrapper}
                  onClick={() => setHelpDialogOpen(true)}
                >
                  <InfoOutlinedIcon color="inherit" />
                  <Typography color="inherit" className={classes.questionInfo}>
                    {question.help.translations.eng}
                  </Typography>
                </Box>
              )}
              {question?.description?.translations.eng && (
                <Box
                  className={classes.questionDescription}
                  sx={{
                    '& a > span': {
                      color: `${questionnaireThemeColor} !important`,
                    },
                  }}
                >
                  <HTMLTextComponent
                    HTMLString={question.description.translations.eng}
                  />
                </Box>
              )}
              <Typography>{questionDescription}</Typography>
              {question?.type && questionTypes[question.type]}
            </PageWrapper>
            {isMobile && !isLoading && <Observer callback={observe} />}
          </Box>
          {!isLoading && overFlowIndicator && (
            <div
              className={
                appRunningInstalled
                  ? `${classes.overflowIndicator} installed`
                  : classes.overflowIndicator
              }
            >
              <div
                className={
                  question.enableAlpha
                    ? `${classes.iconWrapper} top alphaIndex`
                    : `${classes.iconWrapper} top`
                }
              >
                <ExpandMoreRoundedIcon
                  sx={{ color: questionnaireThemeColor }}
                />
              </div>
              <div
                className={
                  question.enableAlpha
                    ? `${classes.iconWrapper} bottom alphaIndex`
                    : `${classes.iconWrapper} bottom`
                }
              >
                <ExpandMoreRoundedIcon
                  sx={{ color: questionnaireThemeColor }}
                />
              </div>
            </div>
          )}
          <NativeButtonWrapper>
            {question?.type === 'slider' && (
              <Typography
                style={{
                  color: questionnaireThemeColor,
                }}
              >
                {i18n.virtual_consult_slide_to_pick}
              </Typography>
            )}

            {!isAutoSubmitQuestion && (
              <Button
                type="submit"
                disabled={!canContinue}
                sx={{
                  margin: '10px auto',
                  padding: '10px',
                  width: '100%',
                  color: '#FFF !important',
                  backgroundColor: `${questionnaireThemeColor} !important`,
                  borderColor: `${questionnaireThemeColor} !important`,
                  [theme.breakpoints.up(960)]: {
                    '&:hover': {
                      backgroundColor: '#fff !important',
                      borderColor: `${questionnaireThemeColor} !important`,
                      color: `${questionnaireThemeColor} !important`,
                    },
                  },
                  [theme.breakpoints.down(600)]: {
                    width: '90% !important',
                  },
                }}
              >
                {i18n.continue_button}
              </Button>
            )}
            {isMobile === false && <Observer callback={observe} />}
          </NativeButtonWrapper>
        </Box>
        <Dialog open={helpDialogOpen}>
          <HelpDialog
            handleClose={() => setHelpDialogOpen(false)}
            body={question.helpDescription?.translations?.eng || ''}
          />
        </Dialog>
      </form>
    </>
  )
}

const HelpDialog = (props: { handleClose: () => void; body: string }) => {
  const { handleClose, body } = props
  const classes = useStyles({ color: QuestionnaireColorType.secondary })

  return (
    <div className={classes.dialogWrapper}>
      <div className={classes.dialogContent}>
        <HTMLTextComponent HTMLString={body} />
      </div>
      <div>
        <div onClick={() => handleClose()}>
          <CloseRoundedIcon style={{ color: '#757575' }} />
        </div>
      </div>
    </div>
  )
}

const PageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

const useStyles = makeStyles((theme) => ({
  'form': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '16px',
  },
  'errorWrapper': {
    boxSizing: 'border-box',
    maxWidth: 625,
    width: '100%',
    margin: '0 auto',
    padding: 16,
  },
  'questionWrapper': {
    flexGrow: 1,
    alignContent: 'space-between',
    maxWidth: 600,
    width: '100vw',
    padding: '0px 16px',
    [theme.breakpoints.down(600)]: {
      padding: '0px',
      minWidth: '90vw',
      width: '100%',
    },
  },
  'questionContent': {
    padding: 10,
    overflowX: 'auto',
    [theme.breakpoints.down(600)]: {
      height: 'calc(100vh - 200px)',
    },
  },
  'questionnaireName': {
    fontSize: 14,
    fontWeight: 500,
  },
  'question': {
    color: '#54565B',
    fontSize: 24,
    textAlign: 'left',
    margin: '8px 0px',
    fontWeight: 600,
    [theme.breakpoints.down(600)]: {
      textAlign: 'left',
    },
  },
  'questionDescription': {
    color: '#898989',
    fontStyle: 'italic',
    fontSize: 18,
    textAlign: 'left',
    maxWidth: 500,
    margin: '0px auto 16px',
    [theme.breakpoints.down(600)]: {
      textAlign: 'left',
    },
  },
  'questionImageWrapper': {
    margin: '0px auto',
    width: '100%',
    maxWidth: 600,
  },
  'questionImage': {
    margin: '0px auto',
    width: '100%',
    [theme.breakpoints.down(600)]: {
      width: '100vw',
    },
  },
  'infoWrapper': {
    maxWidth: 'fit-content',
    cursor: 'pointer',
    display: 'flex',
    gap: 8,
  },
  'questionInfo': {
    fontSize: 16,
    fontWeight: 500,
  },
  'dialogWrapper': {
    padding: 16,
    display: 'flex',
  },
  'dialogContent': {
    padding: '16px 8px 16px 32px',
    [theme.breakpoints.down(380)]: {
      padding: '4px 2px 4px 8px',
    },
  },
  'iconWrapper': {
    'position': 'absolute',
    'top': 0,
    '&.top': {
      'transform': 'translate(0,22px)',
      'animation': '$topMotion 1.56s ease-in-out',
      'animationDelay': '1s',
      'animation-iteration-count': 'infinite',
    },
    '&.bottom': {
      'transform': 'translate(0,30px)',
      'animation': '$bottomMotion 1.56s ease-in-out',
      'animationDelay': '1s',
      'animation-iteration-count': 'infinite',
    },
    [theme.breakpoints.down(600)]: {
      '&.alphaIndex': {
        right: 0,
      },
    },
  },
  '@keyframes topMotion': {
    '0%': {
      transform: 'translate(0,22px)',
    },
    '15%': {
      transform: 'translate(0,0px)',
    },
    '50%': {
      transform: 'translate(0,22px)',
    },
    '100%': {
      transform: 'translate(0,22px)',
    },
  },
  '@keyframes bottomMotion': {
    '0%': {
      transform: 'translate(0,30px)',
    },
    '15%': {
      transform: 'translate(0,0px)',
    },
    '50%': {
      transform: 'translate(0,30px)',
    },
    '100%': {
      transform: 'translate(0,30px)',
    },
  },
  'overflowIndicator': {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: 75,
    right: 'calc(50% - 300px)',
    [theme.breakpoints.down(600)]: {
      'bottom': 275,
      'right': 30,
      '&.installed': {
        bottom: 170,
      },
    },
  },
}))
