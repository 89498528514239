import { Box, Dialog } from '@mui/material'
import CallReceivedIcon from '@mui/icons-material/CallReceived'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { FormikProps, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NOTIFICATION_TYPES, USAStateList } from '../../../constants'
import {
  EncounterType,
  updateEncounterRecord,
} from '../../../features/encounter/model'
import {
  getHealthServiceList,
  selectHealthServiceList,
} from '../../../features/health-service-lite/model'
import { showNotification } from '../../../features/notifications/model'
import { I18n } from '../../../features/translation/types'
import { isAddressExists } from '../../../libs/helpers'
import { useLocationService } from '../../../libs/hooks/useLocation'
import { cleanPhoneNumber } from '../../../libs/utils'
import { lsClient } from '../../../ls-client'
import {
  Button,
  FilledTextField,
  Loading,
  MaskedInput,
  Typography,
} from '../../../ui'
import { FilledSelect } from '../../../ui/atoms/select'
import {
  TextField,
  TextFieldProps,
} from '../../../ui/atoms/text-field/text-field'
import { selectProfileData, selectProfileIsLoading } from '../../profile/model'
import { CurrentOptions } from '../../questionnaireV2/types'
import { telehealthStyles } from '../components/styles'
import { selectTelehealthSessionLoading } from '../model'
import { CurrentLocation, CurrentPhoneNumber } from './helpers/current-options'
import { FormValues } from './helpers/formik/types'
import { validationSchema } from './helpers/formik/validationSchema'

const allowedCountries = ['US']

export const ConfirmLocation = (props: {
  i18n: I18n
  onConfirm: () => void
}) => {
  const { i18n, onConfirm } = props
  const dispatch = useDispatch()
  const classes = telehealthStyles()
  const telehealthLoading = useSelector(selectTelehealthSessionLoading)
  const profileLoading = useSelector(selectProfileIsLoading)
  const healthServiceList = useSelector(selectHealthServiceList)
  const profileData = useSelector(selectProfileData)
  const [editType, setEditType] = useState<CurrentOptions | ''>('')
  const [hasMissingData, setHasMissingData] = useState(false)
  const [isReady, setReady] = useState(false)
  const { line1, line2, city, state, zip, country } =
    profileData.primaryAddress || {}
  const [initialState, setInitialState] = useState({
    contact: {
      mobileNumber: profileData.contact.mobileNumber,
    },
    currentLocation: {
      line1,
      line2,
      city,
      state,
      country,
      zip,
    },
  })
  const healthServiceId = lsClient.getUserLSByKey('healthServiceId')
  const onSubmit = async (values: FormValues) => {
    const { line1, line2, city, state, country, zip, phone } = values

    const encounterParams = {
      _id: healthServiceId,
      type: EncounterType.SYNC_CONSULT_PHONE, // Hardcode to phone for now
      currentLocation: {
        line1,
        line2,
        city,
        state,
        // country,
        zip,
      },
      currentContact: {
        countryCode: '+1',
        phoneNumber: phone,
      },
    }

    dispatch(updateEncounterRecord(encounterParams, onConfirm))
    setEditType('')
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      line1: line1 || '',
      line2: line2 || '',
      city: city || '',
      state: state || '',
      country: country || '',
      zip: zip || '',
      phone: profileData?.contact.mobileNumber || '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema(i18n),
    onSubmit,
  })

  useEffect(() => {
    if (profileData.primaryAddress) setReady(true)

    if (
      profileData.email &&
      profileData._id &&
      healthServiceList.length === 0
    ) {
      dispatch(
        getHealthServiceList(profileData._id, 'InProgress', profileData.email)
      )
    }
    if (profileData.email && !(line1 && city && state && zip)) {
      setHasMissingData(true)
    }
  }, [profileData])

  useEffect(() => {
    if (
      profileData.email &&
      !(
        formik.values.line1 &&
        formik.values.city &&
        formik.values.state &&
        formik.values.zip
      )
    ) {
      setHasMissingData(true)
    } else setHasMissingData(false)
  }, [formik.values])

  const openEditWindow = (type: CurrentOptions) => {
    setInitialState({
      contact: {
        mobileNumber: formik.values.phone,
      },
      currentLocation: {
        line1: formik.values.line1,
        line2: formik.values.line2,
        city: formik.values.city,
        state: formik.values.state,
        country: formik.values.country,
        zip: formik.values.zip,
      },
    })
    setEditType(type)
  }

  const discardEditChanges = () => {
    formik.setFieldValue('phone', initialState.contact.mobileNumber)
    let field: keyof typeof initialState.currentLocation
    for (field in initialState.currentLocation) {
      formik.setFieldValue(`${field}`, initialState.currentLocation[`${field}`])
    }
    setEditType('')
  }

  const updateInfo = () => {
    onSubmit(formik.values)
    setEditType('')
  }

  const enableContinue =
    isAddressExists(formik.values) && profileData.contact.mobileNumber

  if (!isReady) return null

  return (
    <>
      <div className={classes.root}>
        <Box className={classes.messageContainer}>
          <Typography
            variant="h4"
            align="center"
            style={{ marginBottom: '24px' }}
          >
            {i18n.telehealth_intake_title}
          </Typography>
          <Typography align="center" className={classes.mediumBodyText}>
            {i18n.telehealth_intake_message}
          </Typography>
          <CurrentPhoneNumber
            i18n={i18n}
            type="phone"
            profileData={profileData}
            formik={formik}
            setEditType={openEditWindow}
          />
          <CurrentLocation
            i18n={i18n}
            type="current-address"
            profileData={profileData}
            formik={formik}
            setEditType={openEditWindow}
            hasMissingData={hasMissingData}
          />
          <Button
            className={classes.button}
            onClick={updateInfo}
            disabled={!enableContinue || profileLoading || telehealthLoading}
          >
            {i18n.checkout_confirm_label}
          </Button>
        </Box>
      </div>
      {editType === 'phone' && (
        <EditPhoneDialog
          open={true}
          i18n={i18n}
          discardChanges={discardEditChanges}
          formik={formik}
          updateInfo={setEditType}
        />
      )}
      {editType === 'current-address' && (
        <EditShippingDialog
          open={true}
          i18n={i18n}
          allowedCountries={allowedCountries}
          discardChanges={discardEditChanges}
          formik={formik}
          updateInfo={setEditType}
        />
      )}
    </>
  )
}

const EditPhoneDialog = (props: {
  open: boolean
  i18n: I18n
  discardChanges: () => void
  formik: FormikProps<FormValues>
  updateInfo: (state: CurrentOptions | '') => void
}) => {
  const { open, discardChanges, i18n, formik, updateInfo } = props
  const classes = telehealthStyles()

  const values = formik.values
  const errors = formik.errors
  const touched = formik.touched

  const onPhoneChange = (e: React.ChangeEvent<{ value: string }>) => {
    const cleanNumber = cleanPhoneNumber(e.target.value)
    formik.setFieldValue('phone', cleanNumber)
  }

  return (
    <Dialog open={open} fullScreen>
      <Box className={classes.dialogWrapper} style={{ height: '100vh' }}>
        <Box className={classes.dialogContent}>
          <Box
            className={classes.dialogHeader}
            style={{ alignSelf: 'flex-start' }}
          >
            <CallReceivedIcon
              color="primary"
              style={{ marginLeft: '-2px' }}
              className={classes.arrowBack}
              onClick={discardChanges}
            />
          </Box>
          <div className={classes.formWrapper}>
            <Box className={classes.locationWrapper}>
              <Typography variant="h4" style={{ marginBottom: '24px' }}>
                Edit phone number
              </Typography>
              <Typography variant="body1" className={classes.mediumBodyText}>
                {i18n.telehealth_intake_phone_num_msg}
              </Typography>
            </Box>
            <MaskedInput
              mask="+1 (999) 999-9999"
              maskChar=""
              value={values.phone}
              variant="filled"
              name="phone"
              onChange={(e) => onPhoneChange(e)}
              label={i18n.phone_number}
              onBlur={formik.handleBlur}
              error={touched?.phone && Boolean(errors?.phone)}
              helperText={touched?.phone ? errors?.phone : ''}
              required
            />
            <Box className={classes.lgButtonBox}>
              <Button
                onClick={() => updateInfo('')}
                className={classes.button}
                disabled={Boolean(errors?.phone)}
              >
                {i18n.checkout_update_label}
              </Button>
            </Box>
          </div>
        </Box>
      </Box>
    </Dialog>
  )
}

const EditShippingDialog = (props: {
  open: boolean
  i18n: I18n
  allowedCountries?: string[]
  discardChanges: () => void
  formik: FormikProps<FormValues>
  updateInfo: (state: CurrentOptions | '') => void
}) => {
  const {
    open,
    discardChanges,
    i18n,
    formik,
    updateInfo,
    allowedCountries = ['US'],
  } = props
  const classes = telehealthStyles()
  const dispatch = useDispatch()
  const { state, actions } = useLocationService()
  const values = formik.values
  const errors = formik.errors
  const touched = formik.touched
  const hasFormError = Boolean(
    errors.city || errors.line1 || errors.state || errors.zip || errors.country
  )

  useEffect(() => {
    if (state.foundAddress) {
      formik.setFieldValue('line1', state.foundAddress.line1)
      formik.setFieldValue('city', state.foundAddress.city)
      formik.setFieldValue('state', state.foundAddress.state)
      formik.setFieldValue('zip', state.foundAddress.zip)
      setTimeout(() => formik.validateForm(), 100)
    }
  }, [state.foundAddress])

  useEffect(() => {
    if (state.error) {
      dispatch(
        showNotification(
          i18n.location_not_enabled_instructions,
          NOTIFICATION_TYPES.V2_ERROR,
          undefined,
          undefined
        )
      )
    }
  }, [state.error])

  return (
    <Dialog open={open} fullScreen>
      <Box className={classes.dialogWrapper}>
        <Box className={classes.dialogContent}>
          <Box
            className={classes.dialogHeader}
            style={{ alignSelf: 'flex-start' }}
          >
            <CallReceivedIcon
              color="primary"
              style={{ marginLeft: '-2px' }}
              className={classes.arrowBack}
              onClick={discardChanges}
            />
          </Box>
          <div className={classes.formWrapper}>
            <form className={classes.form}>
              <Box className={classes.locationWrapper}>
                <Typography variant="h4" style={{ marginBottom: '24px' }}>
                  Edit current location
                </Typography>
                <Typography variant="body1" className={classes.mediumBodyText}>
                  Used to locate pharmacies near you and pairs you with a
                  licensed provider in your state.
                </Typography>
                <div
                  className={classes.useCurrentContainer}
                  onClick={() => actions.getCurrentLocation('address')}
                >
                  {state.gettingLocation ? (
                    <div className={classes.loadingOverlay}>
                      <Loading />
                    </div>
                  ) : (
                    <LocationOnOutlinedIcon
                      color="primary"
                      style={{ margin: '8px 8px' }}
                    />
                  )}
                  <Typography variant="body1" color="primary">
                    Use my current location
                  </Typography>
                </div>
                <Typography variant="body1" className={classes.edit}>
                  Or edit directly
                </Typography>
              </Box>
              <div className={classes.entries}>
                <FilledTextField
                  label={i18n.street_address}
                  autoComplete="address-line1"
                  name={'line1'}
                  type={'text'}
                  value={values.line1}
                  error={touched.line1 && Boolean(errors.line1)}
                  helperText={touched.line1 ? errors.line1 : ''}
                  variant="filled"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  disabled={state.gettingLocation}
                />
                <FilledTextField
                  label={i18n.patient_records_apt_or_suit}
                  autoComplete="address-line2"
                  name={'line2'}
                  type={'text'}
                  value={values.line2}
                  error={touched.line2 && Boolean(errors.line2)}
                  helperText={touched.line2 ? errors.line2 : ''}
                  variant="filled"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={state.gettingLocation}
                />
                <FilledTextField
                  label={i18n.city}
                  autoComplete="address-level2"
                  name={'city'}
                  type={'text'}
                  value={values.city}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city ? errors.city : ''}
                  variant="filled"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  disabled={state.gettingLocation}
                />
                <FilledSelect
                  className={classes.selectElm}
                  label={i18n.state}
                  options={USAStateList}
                  autoComplete="address-level1"
                  value={values.state}
                  fullWidth
                  variant="filled"
                  name={'state'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={touched.state && Boolean(errors.state)}
                  helperText={touched.state ? errors.state : ''}
                  required
                  disabled={state.gettingLocation}
                />
                <FilledSelect
                  className={classes.selectElm}
                  label={i18n.country}
                  options={[{ value: 'United States', label: 'United States' }]}
                  autoComplete="country-name"
                  value={values.country}
                  fullWidth
                  variant="filled"
                  name={'country'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={touched.country && Boolean(errors.country)}
                  helperText={touched.country ? errors.country : ''}
                  required
                  disabled={state.gettingLocation}
                />
                <MaskedInput
                  mask="99999"
                  maskChar=""
                  label={i18n.zip_code}
                  value={values.zip}
                  variant="filled"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={touched.zip && Boolean(errors.zip)}
                  helperText={touched.zip ? errors.zip : ''}
                  name={'zip'}
                  required
                  disabled={state.gettingLocation}
                />
              </div>
              <Box className={classes.lgButtonBox}>
                <Button
                  onClick={() => updateInfo('')}
                  className={classes.button}
                  disabled={state.gettingLocation || hasFormError}
                >
                  {i18n.checkout_update_label}
                </Button>
              </Box>
            </form>
          </div>
        </Box>
      </Box>
    </Dialog>
  )
}
