import { Box, Link as MUILink } from '@mui/material'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ALPHA_REGEX, StepType } from '../../../../constants'
import { selectLanguageContent } from '../../../../features/translation'
import { Button, FilledTextField } from '../../../../ui'
import { onboardingStyles } from '../../../_styles/registrationStyles'
import { paths } from '../../../paths'
import { getName, scrollTop } from '../../helpers'
import {
  requestRegistrationEmail,
  resetError,
  resetRegisterForm,
  saveSignupInfo,
  selectRegisterError,
  selectVerificationSubmitting,
} from '../../model/registerSlice'
import { RegisterStepProps } from '../../model/type'
import { validateEntry } from '../../../../libs/utils'

export const SignUp = (props: RegisterStepProps) => {
  const { handleNext, formik, accountId, tenantId } = props
  const classes = onboardingStyles()
  const type = StepType.SIGN_UP
  const isLoading = useSelector(selectVerificationSubmitting)
  const error = useSelector(selectRegisterError)
  const i18n = useSelector(selectLanguageContent)
  const navigate = useNavigate()

  useEffect(() => {
    document.addEventListener('focusout', scrollTop)
    return () => {
      document.removeEventListener('focusout', scrollTop)
    }
  }, [])

  const dispatch = useDispatch()

  const values = formik.values[type]
  const errors = formik.errors[type]
  const touched = formik.touched[type]

  useEffect(() => {
    if (error) {
      dispatch(resetError())
    }
  }, [values.email])

  const goNext = () => {
    localStorage.setItem('email', values.email)
    dispatch(saveSignupInfo(values))
    const data = {
      ...values,
      tenantId,
      accountId,
    }
    dispatch(requestRegistrationEmail(data, false, i18n, handleNext))
  }

  const handleSignIn = () => {
    dispatch(resetRegisterForm())
    localStorage.removeItem('tenantId')
    navigate(paths.login())
  }

  return (
    <>
      <form className={classes.content}>
        <Typography className={classes.title} variant="h1">
          {i18n.sign_up_header}
        </Typography>
        <Box className={classes.twoFieldsAlways}>
          <FilledTextField
            label={i18n.first_name}
            autoComplete="given-name"
            name={getName('firstName', type)}
            type={'text'}
            value={values?.firstName}
            error={touched?.firstName && Boolean(errors?.firstName)}
            disabled={values.hasPrefillFN}
            onKeyDown={(event) => validateEntry(event, ALPHA_REGEX)}
            helperText={(touched?.firstName && errors?.firstName) || ''}
            variant="filled"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          <FilledTextField
            label={i18n.last_name}
            autoComplete="family-name"
            name={getName('lastName', type)}
            type={'text'}
            value={values?.lastName}
            error={touched?.lastName && Boolean(errors?.lastName)}
            disabled={values.hasPrefillLN}
            onKeyDown={(event) => validateEntry(event, ALPHA_REGEX)}
            helperText={(touched?.lastName && errors?.lastName) || ''}
            variant="filled"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
        </Box>
        <FilledTextField
          label={i18n.email}
          autoComplete="email"
          name={getName('email', type)}
          type={'text'}
          value={values?.email}
          error={Boolean(error) || (touched?.email && Boolean(errors?.email))}
          helperText={error || (touched?.email && errors?.email) || ''}
          variant="filled"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
        />
        <Typography className={classes.body}>
          {i18n.sign_up_disclaimer}
        </Typography>
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.actionBtn}
            onClick={goNext}
            disabled={!formik.isValid || Boolean(error)}
            isLoading={formik.isSubmitting}
          >
            {i18n.sign_up_button}
          </Button>
        </div>
      </form>
      <Box className={classes.actionText}>
        <Typography className={classes.account}>
          {i18n.already_have_account_label}
        </Typography>
        <MUILink
          role="button"
          className={classes.pageLink}
          onClick={handleSignIn}
        >
          {i18n.sign_in}
        </MUILink>
      </Box>
    </>
  )
}
