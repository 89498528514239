import { Dialog, Divider, styled, Typography } from '@mui/material'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { NOTIFICATION_TYPES } from '../../constants'
import { handleMessage } from '../../libs/helpers'
import { useMUITheme } from '../../libs/hooks/useTheme'
import { capitalizeString } from '../../libs/utils'
import { shopifyStoreStyles } from '../../pages/_styles/shopifyStoreStyles'
import { paths } from '../../pages/paths'
import { TermsAndCondsPage } from '../../pages/terms-and-conds/appTerms'
import { Address } from '../../types/user'
import { Button } from '../../ui'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { addLinkWithinString } from '../details-helper/fields'
import { useDialog } from '../dialog/application-dialog'
import { selectLanguageContent } from '../translation'
import { StoreStepType, UserDataMethods } from './model/types'
import { UpdateShippingAddressParameters } from './SAFE-ecommerce-service/EcommerceSDK'
import { CheckoutItem, LineItem, TipAndInstructions } from './shopify-ui-items'
import { CheckoutUserData } from './shopify-ui-items/checkout-userdata-methods'
import { ChangeAddress } from './shopify-ui-items/checkoutMethods/address'
import { ConfirmAddressWrapper } from './shopify-ui-items/checkoutMethods/confirm-address'
import { TipMethodOptions } from './shopify-ui-items/checkoutMethods/doordash-tip-inst'
import { PaymentMethods } from './shopify-ui-items/checkoutMethods/payment-method'
import { ShippingMethod } from './shopify-ui-items/checkoutMethods/shipping-method'
import { ShopLoading } from './shopify-ui-items/storeLoading'

export const ShopifyCheckout = (props: { sdk: any }) => {
  const { sdk } = props
  const classes = shopifyStoreStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const [isLoading, setIsloading] = useState(false)
  const [checkoutState, setCheckoutState] = useState(
    sdk.stateManager.currentCheckoutViewState()
  )
  let isObserverSetup = false
  const [updateSelected, setUpdateSelected] = useState('')
  const [enteredAddress, setEnteredAddress] = useState<undefined | Address>(
    undefined
  )
  const [foundAddress, setFoundAddress] = useState<undefined | Address>(
    undefined
  )
  const [canProceed, setCanProceed] = useState(false)
  const [methodLoading, setMethodLoading] = useState<UserDataMethods | ''>('')
  const { openDialog, closeDialog } = useDialog()

  const isMobile = useMUITheme().isMobile
  useEffect(() => {
    setIsloading(true)
    setupObserver()
    setTimeout(() => {
      loadCheckout()
    }, 250)
  }, [])

  function setupObserver() {
    if (isObserverSetup) {
      return
    }
    sdk.stateManager.observeCheckoutViewState(
      (checkoutViewState: any) => {
        setCheckoutState(checkoutViewState)
      },
      (error: any) => {
        console.log('Error observing products view state: ', error)
      }
    )
    isObserverSetup = true
  }

  const submitAddressAnyway = () => {
    closeDialog()
    if (!enteredAddress) return
    updateAddress(enteredAddress, UserDataMethods.SHIPPING_ADDRESS, true)
  }

  const errorDialogOptions = {
    addressNotFound: {
      title: i18n.address_not_found,
      body: i18n.shopify_address_not_found_instruction,
      cancelLabel: i18n.proceed_anyway,
      onCancel: () => submitAddressAnyway(),
      confirmationLabel: i18n.try_again,
      onConfirm: () => closeDialog(),
    },
  }

  const checkoutStatus = () => {
    setIsloading(true)
    sdk.checkoutManager
      .confirmOrder()
      .then((response: any) => {
        if (response.canProceedToProcessing) {
          setCanProceed(true)
        }
        setIsloading(false)
      })
      .catch((error: any) => {
        dispatch(handleMessage(error.message, NOTIFICATION_TYPES.V2_ERROR))
        console.log(error)
        setIsloading(false)
      })
  }

  function loadCheckout() {
    if (isLoading) {
      return
    }
    setIsloading(true)
    sdk.checkoutManager
      .loadCheckout()
      .then((response: any) => {
        checkoutStatus()
        setIsloading(false)
      })
      .catch((error: any) => {
        dispatch(handleMessage(error.message, NOTIFICATION_TYPES.V2_ERROR))
        console.log(error)
        setIsloading(false)
      })
  }

  function selectShippingMethod(parameters: any) {
    setMethodLoading(UserDataMethods.SHIPPING_METHOD)
    sdk.checkoutManager
      .selectShippingMethod(parameters)
      .then((response: any) => {
        checkoutStatus()
        setMethodLoading('')
        setUpdateSelected('')
      })
      .catch((error: any) => {
        dispatch(handleMessage(error.message, NOTIFICATION_TYPES.V2_ERROR))
        console.log(error)
        setMethodLoading('')
      })
  }

  function selectTipOptionMethod(parameters: any, type: TipMethodOptions) {
    setMethodLoading(UserDataMethods.DELIVERY_TIP)
    if (type === 'tip') {
      sdk.checkoutManager
        .selectTip(parameters)
        .then((response: any) => {
          checkoutStatus()
          setMethodLoading('')
          setUpdateSelected('')
        })
        .catch((error: any) => {
          dispatch(handleMessage(error.message, NOTIFICATION_TYPES.V2_ERROR))
          console.log(error)
          setMethodLoading('')
        })
    } else {
      sdk.checkoutManager
        .updateDeliveryInstructions(parameters)
        .then((response: any) => {
          setMethodLoading('')
          setUpdateSelected('')
        })
        .catch((error: any) => {
          dispatch(handleMessage(error.message, NOTIFICATION_TYPES.V2_ERROR))
          console.log(error)
          setMethodLoading('')
        })
    }
  }

  function selectPaymentMethod(parameters: any) {
    setMethodLoading(UserDataMethods.PAYMENT_METHOD)
    sdk.checkoutManager
      .createPaymentMethod(parameters)
      .then((response: any) => {
        checkoutStatus()
        setMethodLoading('')
        setUpdateSelected('')
      })
      .catch((error: any) => {
        dispatch(handleMessage(error.message, NOTIFICATION_TYPES.V2_ERROR))
        console.log(error)
        setMethodLoading('')
      })
  }
  const getAddressObject = (addressResponse: any) => ({
    line1: capitalizeString(addressResponse.line1),
    line2: capitalizeString(addressResponse.line2),
    city: capitalizeString(addressResponse.city),
    state: addressResponse.state,
    country: capitalizeString(addressResponse.countryRaw),
    zip: addressResponse.zipCode,
  })

  function updateAddress(
    parameters: any,
    type: UserDataMethods.BILLING_ADDRESS | UserDataMethods.SHIPPING_ADDRESS,
    skipsUSPSValidation: boolean
  ) {
    setMethodLoading(type)

    if (type === UserDataMethods.SHIPPING_ADDRESS) {
      const shippingData = new UpdateShippingAddressParameters(
        parameters,
        skipsUSPSValidation
      )
      sdk.checkoutManager
        .updateShippingAddress(shippingData)
        .then((response: any) => {
          if (response.needsVerification) {
            setEnteredAddress(getAddressObject(response.addressEntered))
            setFoundAddress(getAddressObject(response.addressFound))
            setMethodLoading('')
            setUpdateSelected(UserDataMethods.CONFIRM_ADDRESS)
          } else {
            setMethodLoading('')
            setUpdateSelected('')
          }
        })
        .catch((error: any) => {
          console.log(error)
          setEnteredAddress(parameters)
          openDialog(errorDialogOptions.addressNotFound)
          setMethodLoading('')
        })
    } else {
      sdk.checkoutManager
        .updateBillingAddress(parameters)
        .then((response: any) => {
          if (response.fieldErrors && response.fieldErrors.length > 0) {
            setMethodLoading('')
            openDialog({
              ...errorDialogOptions.addressNotFound,
              cancelLabel: undefined,
              onCancel: undefined,
            })
          } else {
            setMethodLoading('')
            setUpdateSelected('')
          }
        })
        .catch((error: any) => {
          console.log(error)
          dispatch(handleMessage(error.message, NOTIFICATION_TYPES.V2_ERROR))
          setMethodLoading('')
        })
    }
  }
  const getDialog = () => {
    switch (updateSelected) {
      case UserDataMethods.SHIPPING_METHOD: {
        return (
          <ShippingMethod
            onChange={selectShippingMethod}
            currentSelectedMethod={checkoutState.selectedShippingMethod}
            i18n={i18n}
            sdk={sdk}
            isLoading={methodLoading === UserDataMethods.SHIPPING_METHOD}
          />
        )
      }
      case UserDataMethods.SHIPPING_ADDRESS: {
        return (
          <ChangeAddress
            onChange={updateAddress}
            i18n={i18n}
            type={UserDataMethods.SHIPPING_ADDRESS}
            isLoading={methodLoading === UserDataMethods.SHIPPING_ADDRESS}
          />
        )
      }
      case UserDataMethods.PAYMENT_METHOD: {
        return (
          <PaymentMethods
            onChange={selectPaymentMethod}
            currentSelectedMethod={undefined}
            i18n={i18n}
            sdk={sdk}
          />
        )
      }
      case UserDataMethods.BILLING_ADDRESS: {
        return (
          <ChangeAddress
            onChange={updateAddress}
            i18n={i18n}
            type={UserDataMethods.BILLING_ADDRESS}
            isLoading={methodLoading === UserDataMethods.BILLING_ADDRESS}
          />
        )
      }
      case UserDataMethods.DELIVERY_TIP: {
        return <></>
      }
      case UserDataMethods.TERMS_AND_CONDITIONS: {
        return <TermsAndCondsPage />
      }
      case UserDataMethods.CONFIRM_ADDRESS: {
        return (
          <ConfirmAddressWrapper
            foundAddress={foundAddress}
            enteredAddress={enteredAddress}
            onChange={updateAddress}
            i18n={i18n}
          />
        )
      }

      default:
        return null
    }
  }

  const goNext = () => {
    setIsloading(true)
    sdk.checkoutManager
      .confirmOrder()
      .then((response: any) => {
        if (response.canProceedToProcessing) {
          sdk.checkoutManager.finalizeCheckout().then((response: any) => {
            setIsloading(false)
            navigate(paths.shopifyStore(StoreStepType.ORDER_PROCESSING))
          })
        } else {
          dispatch(handleMessage('Not ready to complete', NOTIFICATION_TYPES.V2_ERROR))
        }
      })
      .catch((error: any) => {
        dispatch(handleMessage(error.message, NOTIFICATION_TYPES.V2_ERROR))
        console.log(error)
        setIsloading(false)
      })
  }

  const openUserMethod = (id: UserDataMethods) => {
    setUpdateSelected(id)
  }

  const openTerms = () => {
    setUpdateSelected(UserDataMethods.TERMS_AND_CONDITIONS)
  }

  if (isLoading) return <ShopLoading />

  return (
    <ContentWrapper>
      <div className={classes.cartContent}>
        <div className={classes.titleWrapper}>
          <Typography
            variant="h2"
            className={classes.pageTitle}
            style={{ marginBottom: 8 }}
          >
            {i18n['ecommerce.order_summary_header']}
          </Typography>
          <Typography
            className={`${classes.summaryLineText} mobileMargin link`}
          >
            {addLinkWithinString(
              i18n['ecommerce.order_summary_subtitle'],
              i18n['ecommerce.checkout_view.terms_and_conditions_emphasized'],
              () => openTerms(),
              '',
              classes.termsLink
            )}
          </Typography>
        </div>
        <div className={classes.cartWrapper}>
          <div className={classes.inCart}>
            <CheckoutUserData
              id={UserDataMethods.SHIPPING_METHOD}
              actionText={checkoutState.shippingMethodDisplayText}
              title={i18n['ecommerce.shipping_method_header']}
              icon={
                <LocalShippingOutlinedIcon
                  style={{ fontSize: 20, color: '#757575' }}
                />
              }
              handleOpen={openUserMethod}
              hide={
                checkoutState.shippingMethodHidden ||
                !checkoutState.isShippingMethodSelectionEnabled
              }
              isLoading={methodLoading === UserDataMethods.SHIPPING_METHOD}
            />
            <CheckoutUserData
              id={UserDataMethods.SHIPPING_ADDRESS}
              actionText={checkoutState.shippingAddressDisplayText(true, ', ')}
              title={i18n['ecommerce.shipping_address_header']}
              icon={
                <HomeOutlinedIcon style={{ fontSize: 20, color: '#757575' }} />
              }
              handleOpen={openUserMethod}
              hide={checkoutState.shippingAddressHidden}
              isLoading={methodLoading === UserDataMethods.SHIPPING_ADDRESS}
            />
            {checkoutState.canSelectTips && (
              <TipAndInstructions
                i18n={i18n}
                onChangeTipOptions={selectTipOptionMethod}
                currentSelectedMethod={checkoutState.selectedTip}
                tipOptions={checkoutState.selectableTipOptions}
                instructions={checkoutState.deliveryInstructions}
                isLoading={methodLoading === UserDataMethods.DELIVERY_TIP}
              />
            )}
            <CheckoutUserData
              id={UserDataMethods.PAYMENT_METHOD}
              actionText={checkoutState.paymentMethodDisplayText}
              title={i18n['ecommerce.payment_method_header']}
              icon={
                <CreditCardOutlinedIcon
                  style={{ fontSize: 20, color: '#757575' }}
                />
              }
              handleOpen={openUserMethod}
              hide={checkoutState.paymentMethodHidden}
              isLoading={methodLoading === UserDataMethods.PAYMENT_METHOD}
            />

            <CheckoutUserData
              id={UserDataMethods.BILLING_ADDRESS}
              actionText={checkoutState.billingAddressDisplayText(true, ', ')}
              title={i18n['ecommerce.billing_address_header']}
              icon={
                <HomeOutlinedIcon style={{ fontSize: 20, color: '#757575' }} />
              }
              handleOpen={openUserMethod}
              hide={checkoutState.billingAddressHidden}
              isLoading={methodLoading === UserDataMethods.BILLING_ADDRESS}
            />
            <div className={`${classes.dataContentWrapper} orderDetails`}>
              <Typography variant="h2" className={classes.orderSummaryTitle}>
                {i18n['ecommerce.order_details_header']}
              </Typography>
              {checkoutState.orderLines.map((product: any) => (
                <CheckoutItem
                  key={product.productTitle}
                  i18n={i18n}
                  product={product}
                  color="#F5F5F5"
                />
              ))}
            </div>
          </div>
          <div className={`${classes.cartSummaryAnchor} checkout`}>
            {isMobile && (
              <Divider
                style={{ marginBottom: 16, backgroundColor: '#E0E0E0' }}
              />
            )}
            <div className={classes.cartSummary}>
              {!isMobile && (
                <Typography variant="h2" className={classes.orderSummaryTitle}>
                  {i18n['ecommerce.charges_header']}
                </Typography>
              )}
              {checkoutState.chargesDisplayed.map((item: any) => (
                <>
                  <LineItem
                    title={item.label}
                    value={item.amount.printed()}
                    isTotal={false}
                  />
                </>
              ))}
              <Divider
                style={{
                  marginBottom: 16,
                  backgroundColor: '#E0E0E0',
                }}
              />
              <LineItem
                title={i18n['ecommerce.order.charges.total']}
                value={checkoutState.totalAmountDisplayText}
                isTotal={true}
              />
              <div className={classes.buttonWrapper}>
                <Button
                  onClick={goNext}
                  className={`${classes.cartButton} fullWidth`}
                  disabled={!canProceed || Boolean(methodLoading)}
                >
                  {i18n['ecommerce.confirm_order_button']}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={Boolean(updateSelected)} fullScreen>
        <ModuleHeader
          leftContentTitle={i18n.back_button}
          leftContentAction={() => setUpdateSelected('')}
          border={!isMobile}
          color="#505358"
        >
          <>{getDialog()}</>
        </ModuleHeader>
      </Dialog>
    </ContentWrapper>
  )
}

const ContentWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  margin: '0px auto 64px',
  [theme.breakpoints.down(600)]: {
    margin: '0px auto',
  },
}))
