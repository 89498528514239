import { Box } from '@mui/material'
import { styled, Theme } from '@mui/material/styles'
import createDOMPurify from 'dompurify'
import React from 'react'
import { Button, Typography } from '../ui'

const DOMPurify = createDOMPurify(window)

export const HTMLTextComponent = (props: {
  HTMLString: string | null
  className?: string
}) => {
  const { HTMLString, className } = props
  if (!HTMLString) return null
  DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank')
      node.setAttribute('rel', 'noopener')
    }
  })

  return (
    <span
      className={className}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(HTMLString),
      }}
    />
  )
}

export const NativeButton = styled(Button)(({ theme }) => ({
  margin: '10px auto',
  padding: '10px',
  width: '100%',
  [theme.breakpoints.down(600)]: {
    width: '90%',
  },
}))
export const NativeButtonWrapper = styled('div')(
  (props: { backgroundColor?: string; theme: Theme }) => ({
    display: 'flex',
    backgroundColor: props.backgroundColor ? props.backgroundColor : '#FFF',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '32px 0px',
    position: 'relative',
    width: '100%',
    zIndex: 10,
    [props.theme.breakpoints.down(600)]: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      padding: '12px 0px 24px',
      width: '100vw',
    },
  })
)

export const XLResponsiveContentWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 1800,
  margin: '0px auto',
}))

export const MedResponsiveContentWrapper = styled('div')(({ theme }) => ({
  marginTop: 72,
  margin: '0px auto',
  width: '100%',
  maxWidth: '800px',
  [theme.breakpoints.down(960)]: {
    width: '90%',
    marginTop: 24,
  },
}))

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: '#282D37',
  marginBottom: 32,
  fontSize: 36,
  fontWeight: 600,
  [theme.breakpoints.down(600)]: {
    marginBottom: 24,
  },
}))

export const FlexColumnCenter = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

export const CenteredContentWrapper = styled(FlexColumnCenter)(({ theme }) => ({
  width: '100%',
  maxWidth: 600,
  margin: '0px auto',
  [theme.breakpoints.down(600)]: {
    maxWidth: '90%',
  },
}))

export const ContentTitle = styled(Typography)(({ theme }) => ({
  color: '#282D37',
  fontSize: 32,
  fontWeight: 600,
  [theme.breakpoints.down(600)]: {
    fontSize: 24,
  },
}))

export const ContentBody = styled(Typography)(({ theme }) => ({
  color: '#757575',
  fontSize: 16,
  fontWeight: 500,
}))

export const ListWrapper = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 24,
  marginTop: '-1px',
  borderBottom: '1px solid #E0E0E0',
  borderTop: '1px solid #E0E0E0',
  [theme.breakpoints.down(600)]: {
    padding: 16,
  },
}))

export const ListItemTitle = styled(Typography)({
  fontSize: 16,
  fontWeight: 600,
  color: '#505358',
})
