/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useDialog } from '../../../features/dialog/application-dialog'
import { updateEncounterRecord } from '../../../features/encounter/model'
import { useFlowControl } from '../../../features/flowcontrol/use-flow-control'
import { addHS } from '../../../features/health-service-lite/model'
import { selectLanguageContent } from '../../../features/translation'
import { clearTestKitFlow } from '../../../libs/helpers'
import { lsClient } from '../../../ls-client'
import { Loading } from '../../../ui'
import {
  getDiagnosticResult,
  resetOutcomeStore,
  selectDiagnosticOutcomeResponseCode,
  selectDiagnosticResult,
  selectDiagnosticTestStatus,
} from '../../outcomes/model'
import { paths } from '../../paths'
import {
  resetUploadStore,
  selectPatientTestId,
  selectScanUploadResultSuccess,
  selectSubmitPatientTestLoading,
  setIsRetyImage,
} from '../model'
import { selectElapsedTimeOnSubmission } from '../../register-a-test-module/model'

const PE_QUESTIONNAIRE = process.env.REACT_APP_ELIGIBILITY_QUESTIONNAIRE

const errorMessageCodes = [
  'Err1',
  'Err2',
  'Err3',
  'Err4',
  'Err5',
  'Err6',
  'Err7',
  'Err8',
  'Err9',
]

export const UploadProcessingPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [tries, setTries] = useState(0)
  const isLoading = useSelector(selectSubmitPatientTestLoading)
  const testStatus = useSelector(selectDiagnosticTestStatus)
  const response = useSelector(selectDiagnosticOutcomeResponseCode)
  const testQRId = useSelector(selectPatientTestId)
  const result = useSelector(selectDiagnosticResult)
  const uploadSuccess = useSelector(selectScanUploadResultSuccess)
  const elapsedTime = useSelector(selectElapsedTimeOnSubmission)
  const i18n = useSelector(selectLanguageContent)
  const [message, setMessage] = useState(i18n.scan_cassete_processing)
  const { state, actions } = useFlowControl()
  const { openDialog, closeDialog } = useDialog()

  const messageSteps = [
    i18n.scan_cassete_processing,
    i18n.scan_cassete_quality_confirm,
    i18n.scan_cassete_processing_result,
  ]

  const onCancelError = () => {
    clearTestKitFlow()
    closeDialog()
    dispatch(resetUploadStore())
    dispatch(resetOutcomeStore())
    navigate(paths.app.dashboard())
  }

  const cancelWarning = () => {
    const content = {
      title: i18n.abandon_test_flow_title,
      body: i18n.abandon_test_flow_message,
      onCancel: () =>
        openDialog({
          ...defaultErrorActions,
          ...mlErrorMessages[response],
        }),
      cancelLabel: i18n.return_to_test,
      onConfirm: onCancelError,
      confirmationLabel: i18n.abandon_test,
    }
    openDialog(content)
  }

  const retryError = () => {
    dispatch(setIsRetyImage(true))
    closeDialog()
    dispatch(resetOutcomeStore())
    navigate(paths.instructions())
  }

  const defaultErrorActions = {
    onConfirm: retryError,
    confirmationLabel: i18n.try_again,
    onCancel: cancelWarning,
    cancelLabel: i18n.cancel_button,
  }

  const mlErrorMessages = {
    Err1: {
      title: i18n.ml_result_code_error_01_title,
      body: i18n.ml_result_code_error_01_msg,
    },
    Err2: {
      title: i18n.ml_result_code_error_02_title,
      body: i18n.ml_result_code_error_02_msg,
    },
    Err3: {
      title: i18n.ml_result_code_error_03_title,
      body: i18n.ml_result_code_error_03_msg,
    },
    Err4: {
      title: i18n.ml_result_code_error_04_title,
      body: i18n.ml_result_code_error_04_msg,
    },
    Err5: {
      title: i18n.ml_result_code_error_05_title,
      body: i18n.ml_result_code_error_05_msg,
    },
    Err6: {
      title: i18n.ml_result_code_error_06_title,
      body: i18n.ml_result_code_error_06_msg,
    },
    Err7: {
      title: i18n.ml_result_code_error_07_title,
      body: i18n.ml_result_code_error_07_msg,
    },
    Err8: {
      title: i18n.ml_result_code_error_08_title,
      body: i18n.ml_result_code_error_08_msg,
    },
    Err9: {
      title: i18n.ml_result_code_error_09_title,
      body: i18n.ml_result_code_error_08_msg,
    },
  } as any

  useEffect(() => {
    if (isLoading) return
    const healthServiceId = lsClient.getUserLSByKey('healthServiceId')
    if (testStatus === 'COMPLETED') {
      if (errorMessageCodes.includes(response)) {
        const error = {
          ...defaultErrorActions,
          ...mlErrorMessages[response],
        }
        openDialog(error)
      } else {
        clearTestKitFlow()
        if (healthServiceId) {
          const encounterParams = {
            _id: healthServiceId,
            status: 0,
            patientTestIds: [result?._id],
          }
          dispatch(updateEncounterRecord(encounterParams))
          if (result) {
            const data = {
              id: result._id,
              type: 'Screening',
            }
            //next - Flow Control
            dispatch(addHS(healthServiceId, data, 'connectedTests'))
            if (lsClient.getUserLSByKey('flowId')) {
              actions.next({ out: result._id })
              // dispatch(resetUploadStore())
            } else {
              // dispatch(resetUploadStore())
              navigate(paths.symptomCheckerIntroById(PE_QUESTIONNAIRE))

              // navigate(paths.uploadSuccessful())
            }
          }
          // todo: add path for Invalid result "More Testing Needed"
        } else navigate(paths.uploadSuccessful())
      }
    } else if (errorMessageCodes.includes(response)) {
      const error = {
        ...defaultErrorActions,
        ...mlErrorMessages[response],
      }
      openDialog(error)
    } else if (uploadSuccess && testStatus !== 'COMPLETED' && tries <= 5) {
      const timeout = setTimeout(() => {
        if (testQRId) {
          dispatch(getDiagnosticResult(testQRId, elapsedTime))
        }
        if (tries < 2) {
          setMessage(messageSteps[tries + 1])
        }
        setTries(tries + 1)
      }, 5000)
      return () => {
        clearTimeout(timeout)
      }
    }
    // else {
    //   if (healthServiceId) {
    //     const data = {
    //       id: testQRId,
    //       type: 'Screening',
    //     }
    //     dispatch(addHS(healthServiceId, data, 'connectedTests'))
    //   }
    //   dispatch(resetUploadStore())
    //   navigate(paths.uploadFailed())
    // }
  }, [tries, testQRId, isLoading, uploadSuccess])

  return (
    <Box className={classes.loadingContainer}>
      <Box className={classes.loadingWrapper}>
        <Box className={classes.spinner}>
          <Loading size={100} />
        </Box>
        <Typography variant="h6" className={classes.processText}>
          {message}
        </Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((_theme) => ({
  spinner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingContainer: {
    margin: '0 auto',
    width: '100%',
    maxWidth: '500px',
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    justifyContent: 'center',
    maxWidth: 540,
    margin: '0 auto',
    textAlign: 'center',
  },
  processText: {
    fontSize: 32,
    color: '#282D37',
    margin: 32,
  },
}))
