import { Box } from '@mui/material'
import { makeStyles, ClassNameMap } from '@mui/styles'
import CallReceivedIcon from '@mui/icons-material/CallReceived'
import CloseIcon from '@mui/icons-material/Close'
import React, { ReactChild } from 'react'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { XLResponsiveContentWrapper } from '../../../libs/shared-components'
import { getColor } from '../../../libs/utils'
import { ColorWithHex } from '../../../types/general'
import { Typography } from '../../atoms'
import { ProgressBar } from '../../atoms/progress-bar'

interface ModuleHeaderProps {
  leftContentTitle?: string
  leftContentAction?: () => void
  leftActionIcon?: React.ReactNode
  centerContentTitle?: string
  centerTitleStyleOptions?: Record<string, unknown>
  centerContentImage?: string
  centerContentAction?: () => void
  rightContentTitle?: string
  rightContentAction?: () => void
  rightActionIcon?: React.ReactNode
  secondRightContentTitle?: string
  secondRightContentAction?: () => void
  secondRightActionIcon?: React.ReactNode
  progress?: number
  progressColor?: ColorWithHex
  color?: ColorWithHex
  children?: ReactChild
  border?: boolean
}

interface ElementProps extends ModuleHeaderProps {
  classes: ClassNameMap
}

export function ModuleHeader(props: ModuleHeaderProps) {
  const { color = 'primary', children, border, progress, progressColor } = props
  const classes = useStyles({ color })
  const { isMobile } = useMUITheme()
  return (
    <div className={classes.wrapper}>
      <div
        className={
          border || !isMobile ? `${classes.topBar} border` : classes.topBar
        }
      >
        <XLResponsiveContentWrapper>
          <Box className={classes.mainTopTitle}>
            <LeftContent {...props} classes={classes} />
            <CenterContent {...props} classes={classes} />
            <RightContent {...props} classes={classes} />
          </Box>
        </XLResponsiveContentWrapper>
        <ProgressBar progress={progress} progressColor={progressColor} />
      </div>
      <div className={classes.childWrapper}>{children}</div>
    </div>
  )
}

const LeftContent = (props: ElementProps) => {
  const { leftContentTitle, leftContentAction, classes } = props

  return (
    <div className={classes.actionWrapper} onClick={leftContentAction}>
      <div
        className={
          leftContentAction
            ? `${classes.cursorWrapper} hasAction`
            : classes.cursorWrapper
        }
      >
        {leftContentAction && <CallReceivedIcon className={classes.leftIcon} />}
        <Typography className={classes.actionText}>
          {leftContentTitle}
        </Typography>
      </div>
    </div>
  )
}

const CenterContent = (props: ElementProps) => {
  const {
    centerContentTitle,
    centerTitleStyleOptions,
    centerContentImage,
    centerContentAction,
    classes,
  } = props

  return (
    <div
      className={`${classes.actionWrapper} center`}
      onClick={centerContentAction}
    >
      <div
        className={
          centerContentAction
            ? `${classes.cursorWrapper} hasAction`
            : classes.cursorWrapper
        }
      >
        {centerContentTitle && (
          <Typography
            className={classes.actionText}
            style={centerTitleStyleOptions}
          >
            {centerContentTitle}
          </Typography>
        )}
        {centerContentImage && (
          <img src={centerContentImage} className={classes.headerImage} />
        )}
      </div>
    </div>
  )
}

const RightContent = (props: ElementProps) => {
  const {
    rightContentTitle,
    rightContentAction,
    rightActionIcon,
    secondRightContentTitle,
    secondRightContentAction,
    secondRightActionIcon,
    classes,
  } = props

  if (!(rightContentTitle || rightContentAction)) return null

  return (
    <div className={`${classes.actionWrapper} right`}>
      <div
        className={
          secondRightContentAction
            ? `${classes.cursorWrapper} hasAction spacer`
            : `${classes.cursorWrapper} spacer`
        }
        onClick={secondRightContentAction}
      >
        <>
          <Typography className={classes.actionText}>
            {secondRightContentTitle}
          </Typography>
          {secondRightActionIcon}
        </>
      </div>
      <div
        className={`${classes.cursorWrapper} hasAction`}
        onClick={rightContentAction}
      >
        <Typography className={classes.actionText}>
          {rightContentTitle}
        </Typography>
        {rightActionIcon ? (
          <span className={classes.rightIcon}>{rightActionIcon}</span>
        ) : (
          <CloseIcon className={classes.rightIcon} />
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  topBar: {
    'width': '100%',
    'position': 'fixed',
    'top': 0,
    'backgroundColor': '#FFF',
    'zIndex': 101,
    '&.border': {
      borderBottom: '1px solid #efefef',
    },
  },
  leftIcon: {
    marginLeft: 48,
    color: ({ color }: { color: ColorWithHex }) => getColor(color, theme),
    transform: 'rotate(45deg)',
    [theme.breakpoints.down(600)]: {
      marginLeft: '5vw',
    },
  },
  actionWrapper: {
    'minWidth': '33.3%',
    'display': 'flex',
    '&.center': {
      justifyContent: 'center',
    },
    '&.right': {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down(600)]: {
      alignItems: 'flex-end',
    },
  },
  cursorWrapper: {
    'display': 'flex',
    'alignItems': 'center',
    'gap': 8,
    '&.hasAction': {
      cursor: 'pointer',
    },
    '&.spacer': {
      marginRight: 40,
    },
  },
  actionText: {
    fontWeight: 500,
    color: ({ color }: { color: ColorWithHex }) => getColor(color, theme),
    [theme.breakpoints.down(600)]: {
      display: 'none',
    },
  },
  rightIcon: {
    color: ({ color }: { color: ColorWithHex }) => getColor(color, theme),
    marginRight: 48,
    [theme.breakpoints.down(600)]: {
      marginRight: '5vw',
    },
  },
  mainTopTitle: {
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    [theme.breakpoints.down(600)]: {
      alignItems: 'flex-end',
      height: '58px',
      paddingBottom: 6,
    },
  },
  headerImage: {
    maxHeight: 24,
    margin: '0 auto',
    justifySelf: 'center',
  },
  progress: {
    width: '100%',
    margin: '0px auto',
  },
  childWrapper: {
    width: '100%',
    paddingTop: '65px',
  },
}))
