import styled from '@emotion/styled'
import { Grid, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as WriteMessage } from '../../../assets/icons/write_message.svg'
import { SupportDialog } from '../../../pages/contact-support/supportDialog'
import { paths } from '../../../pages/paths'

export const SubBackResources = ({
  title,
  backPath = paths.app.dashboard(),
  showContactSupport,
}: {
  title: string
  backPath?: string
  showContactSupport?: boolean
}) => {
  const classes = useStyles()
  const [supportDialogOpen, setSupportDialogOpen] = useState(false)

  return <>
    <SubBackResourcesWrapper>
      <Grid className={classes.headerTop}>
        <IconButton component={Link} to={backPath} color="inherit" size="large">
          <KeyboardBackspaceIcon style={{ color: '#FFF' }} />
        </IconButton>
        <Typography style={{ color: '#FFF' }} variant="h6" component="h2">
          {title}
        </Typography>
        {showContactSupport && (
          <IconButton onClick={() => setSupportDialogOpen(true)} size="large">
            <WriteMessage />
          </IconButton>
        )}
      </Grid>
    </SubBackResourcesWrapper>
    <SupportDialog
      open={supportDialogOpen}
      setSupportDialogOpen={setSupportDialogOpen}
    />
  </>;
}

const SubBackResourcesWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  grid-template-columns: 48px 1fr 48px;
`

const useStyles = makeStyles((theme) => ({
  headerTop: {
    backgroundColor: theme.palette.primary.main,
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    gridTemplateColumns: '48px 1fr 48px',
    [theme.breakpoints.up(600)]: {
      borderRadius: '8px 8px 0 0',
    },
  },
}))
