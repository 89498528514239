import { Box, Dialog } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import { useSelector } from 'react-redux'
import CancelConsult from '../../../assets/icons/cancel-consult.svg'
import { Button, Typography } from '../../../ui'
import { selectLanguageContent } from '../../translation'
import { I18n } from '../../translation/types'
import { HSDialogs } from '../types'

export const CancelDialog = (props: {
  open: boolean
  endTest: () => void
  setDialogOpen: (state: HSDialogs) => void
}) => {
  const { open, endTest, setDialogOpen } = props
  const i18n = useSelector(selectLanguageContent)
  const classes = useStyles()

  return (
    <Dialog open={open} PaperProps={{ style: { borderRadius: 16 } }}>
      <Box className={classes.schedulePageHolder}>
        <Box
          onClick={() => setDialogOpen('')}
          className={classes.closeIconWrapper}
        >
          <CloseIcon color="primary" />
        </Box>
        <Box className={classes.scheduleWrapper}>
          <Box className={classes.headerWrapper}>
            <img src={CancelConsult} />
            <div>
              <Typography
                variant="h4"
                className={classes.title}
                align="center"
                style={{ marginBottom: '8px' }}
              >
                {i18n.are_you_sure}
              </Typography>
              <Typography
                variant="body1"
                align="center"
                className={classes.body}
              >
                {i18n.cancel_health_service_message}
              </Typography>
            </div>
          </Box>
          <Box className={classes.lgButtonBox}>
            <Button
              onClick={endTest}
              className={classes.confirmButton}
              variant="contained"
            >
              Cancel Health Service
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '26px',
    fontWeight: 500,
    width: '100%',
  },
  closeIconWrapper: {
    position: 'absolute',
    right: '16px',
    top: '16px',
  },
  body: {
    color: '#7b7c7d',
    fontSize: '13px',
    fontWeight: 500,
    width: '100%',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    gap: '24px',
  },
  lgButtonBox: {
    width: '100%',
  },
  confirmButton: {
    minWidth: 'unset',
    maxWidth: '100%',
    padding: '10px 24px',
    width: '100%',
    height: '46px',
    margin: '0px auto',
    [theme.breakpoints.down(576)]: {
      minWidth: '250px',
    },
  },
  schedulePageHolder: {
    maxWidth: '342px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F7F7F7',
    [theme.breakpoints.down(600)]: {
      padding: '0px',
      width: 'unset',
      height: 'unset',
    },
  },
  scheduleWrapper: {
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    margin: '24px',
    gap: '24px',
  },
  step: {
    width: '30%',
    height: '4px',
    margin: '0px 4px',
  },
}))
