import { Box, Link as MUILink, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { Button, FilledTextField } from '../../ui'
import { paths } from '../paths'
import {
  requestPasswordReset,
  selectEmail,
  selectEmailError,
  selectEmailTouched,
  selectPasswordResetError,
  selectPasswordResetLoading,
  setEmail,
  setEmailTouched,
  setError,
} from './passwordResetSlice'

export const ForgotPasswordPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const i18n = useSelector(selectLanguageContent)
  const email = useSelector(selectEmail)
  const emailTouched = useSelector(selectEmailTouched)
  const error = useSelector(selectPasswordResetError)
  const emailError = useSelector(selectEmailError)
  const isLoading = useSelector(selectPasswordResetLoading)
  const submitDisabled = !emailTouched || emailError || isLoading
  const handleSubmit = () => {
    dispatch(
      requestPasswordReset(email, () =>
        navigate(paths.resetConfirmation(email))
      )
    )
  }

  useEffect(() => {
    //Reset any errors and error messages on page load
    dispatch(setError(''))
  }, [])

  return (
    <div className={classes.pageWrapper}>
      <Box className={classes.contentWrapper}>
        <Typography className={classes.title} color="primary" variant="h1">
          {i18n.forgot_password_screen_title}
        </Typography>
        <Box className={classes.instructions}>
          {i18n.enter_forgot_password_email_label}
        </Box>
        <Email i18n={i18n} />
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.actionBtn}
            onClick={handleSubmit}
            disabled={submitDisabled}
          >
            {i18n.continue_button}
          </Button>
        </div>
        <Box marginTop="5px">
          <Box className={classes.actionText}>
            <MUILink
              role="button"
              className={classes.pageLink}
              onClick={() => navigate(paths.login())}
              aria-label="create_new_account"
            >
              Return to sign in
            </MUILink>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

const Email = ({ i18n }: { i18n: I18n }) => {
  const email = useSelector(selectEmail)
  const emailError = useSelector(selectEmailError)
  const error = useSelector(selectPasswordResetError)
  const dispatch = useDispatch()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error) {
      dispatch(setError(''))
    }
    dispatch(setEmail(e.target.value))
  }

  return (
    <FilledTextField
      label={i18n.email}
      value={email}
      required
      autoComplete="email"
      type="text"
      name="email"
      onChange={handleChange}
      onBlur={() => dispatch(setEmailTouched())}
      error={emailError}
      helperText={emailError ? 'Valid email required' : ''}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    'justifyContent': 'center',
    'marginTop': 12,
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    '@media (max-width: 767px)': {
      backgroundColor: '#fff',
    },
  },
  contentWrapper: {
    'display': 'flex',
    'flexDirection': 'column',
    'gap': '1rem',
    'maxWidth': 520,
    'padding': '0 40px',
    'boxSizing': 'border-box',
    'width': '100%',
    'overflow': 'hidden',
    'backgroundColor': '#fff',
    '@media (max-width: 1023px)': {
      width: 503,
    },
  },
  pageLink: {
    marginLeft: 4,
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  actionText: {
    display: 'flex',
    justifyContent: 'center',
    color: '#798dad',
    justifySelf: 'center',
    marginTop: 20,
  },
  buttonWrapper: {
    'marginTop': 'auto',
    'marginBottom': 0,
    '@media (max-width: 1023px)': {
      textAlign: 'center',
    },
  },
  actionBtn: {
    width: '100%',
    justifySelf: 'center',
    maxHeight: 48,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 24,
  },
  title: {
    fontSize: 36,
    color: '#505358',
    textAlign: 'center',
    marginBottom: 8,
    [theme.breakpoints.down('xl')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 24,
    },
  },
  field: {
    width: '100%',
    margin: '100px 0',
  },
  instructions: {
    margin: '40px 15px',
    color: '#54565B',
    fontSize: 16,
    textAlign: 'center',
    fontFamily: 'Avenir Next Rounded regular',
    lineHeight: '22px',
  },
}))
