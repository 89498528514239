import Box from '@mui/material/Box'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { USAStateList } from '../../../../constants'
import { PageTitle } from '../../../../libs/shared-components'
import { shopifyStoreStyles } from '../../../../pages/_styles/shopifyStoreStyles'
import {
  selectProfileData,
  updateUserData,
} from '../../../../pages/profile/model'
import {
  Button,
  Checkbox,
  FilledSelect,
  FilledTextField,
  MaskedInput,
  TextField,
} from '../../../../ui'
import { I18n } from '../../../translation/types'
import { AddressFormValues, UserDataMethods } from '../../model/types'
import { getValidationSchema } from '../../model/validationSchema'
import { AddressModel } from '../../SAFE-ecommerce-service/EcommerceSDK'

export const ChangeAddress = (props: {
  onChange: (
    method: any,
    type: UserDataMethods.BILLING_ADDRESS | UserDataMethods.SHIPPING_ADDRESS,
    skipsUSPSValidation: boolean
  ) => void
  i18n: I18n
  type: UserDataMethods.BILLING_ADDRESS | UserDataMethods.SHIPPING_ADDRESS
  isLoading: boolean
}) => {
  const { i18n, onChange, type, isLoading } = props
  const classes = shopifyStoreStyles()
  const dispatch = useDispatch()
  const profileData = useSelector(selectProfileData)
  const [makeDefault, setMakeDefault] = useState(false)

  useEffect(() => {
    return () => {
      setMakeDefault(false)
    }
  }, [])

  const startingAddress =
    type === UserDataMethods.SHIPPING_ADDRESS
      ? profileData.shippingAddress
      : profileData.billingAddress
  const onSubmit = (values: AddressFormValues) => {
    const { line1, line2, city, state, country, zip } = values

    const parameters = new AddressModel(line1, line2, city, state, country, zip)
    if (makeDefault) {
      const updatedShipping = {
        ...profileData,
        shippingAddress: {
          line1: values.line1,
          line2: values.line2,
          city: values.city,
          state: values.state,
          zip: values.zip,
        },
      }
      const updatedBilling = {
        ...profileData,
        billingAddress: {
          line1: values.line1,
          line2: values.line2,
          city: values.city,
          state: values.state,
          zip: values.zip,
        },
      }
      dispatch(
        updateUserData(
          type === UserDataMethods.SHIPPING_ADDRESS
            ? updatedShipping
            : updatedBilling,
          true
        )
      )
    }
    onChange(parameters, type, type === UserDataMethods.BILLING_ADDRESS)
  }

  const formik = useFormik<AddressFormValues>({
    initialValues: {
      line1: startingAddress?.line1 || '',
      line2: startingAddress?.line2 || '',
      city: startingAddress?.city || '',
      state: startingAddress?.state || '',
      country: startingAddress?.country || '',
      zip: startingAddress?.zip || '',
    },
    enableReinitialize: true,
    validationSchema: getValidationSchema(i18n, type),
    onSubmit,
  })

  const goNext = async () => {}
  const values = formik.values

  return (
    <div className={classes.checkoutMethodContent}>
      <PageTitle style={{ margin: 0 }}>
        {type === UserDataMethods.SHIPPING_ADDRESS
          ? i18n['ecommerce.shipping_address_header']
          : i18n['ecommerce.billing_address_header']}
      </PageTitle>
      <form className={classes.form}>
        <div className={classes.entries}>
          <FilledTextField
            label={i18n.street_address}
            autoComplete="address-line1"
            name={'line1'}
            type={'text'}
            value={formik.values.line1}
            error={formik.touched.line1 && Boolean(formik.errors.line1)}
            helperText={formik.touched.line1 ? formik.errors.line1 : ''}
            variant="filled"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          <div className={classes.twoFields}>
            <FilledTextField
              label={i18n.patient_records_apt_or_suit}
              autoComplete="address-line2"
              name={'line2'}
              type={'text'}
              value={formik.values.line2}
              error={formik.touched.line2 && Boolean(formik.errors.line2)}
              helperText={formik.touched.line2 ? formik.errors.line2 : ''}
              variant="filled"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FilledTextField
              label={i18n.city}
              autoComplete="address-level2"
              name={'city'}
              type={'text'}
              value={formik.values.city}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city ? formik.errors.city : ''}
              variant="filled"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className={classes.twoFields}>
            <FilledSelect
              className={classes.selectElm}
              label={i18n.state}
              options={USAStateList}
              autoComplete="address-level1"
              value={formik.values.state}
              fullWidth
              variant="filled"
              name={'state'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state ? formik.errors.state : ''}
              required
            />
            <FilledSelect
              className={classes.selectElm}
              label={i18n.country}
              options={[{ value: 'United States', label: 'United States' }]}
              autoComplete="country-name"
              value={formik.values.country}
              fullWidth
              variant="filled"
              name={'country'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country ? formik.errors.country : ''}
              required
            />
          </div>
          <MaskedInput
            mask="99999"
            maskChar=""
            label={i18n.zip_code}
            value={formik.values.zip}
            variant="filled"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.zip && Boolean(formik.errors.zip)}
            helperText={formik.touched.zip ? formik.errors.zip : ''}
            name={'zip'}
            required
          />
          <div>
            <Checkbox
              label={
                type === UserDataMethods.SHIPPING_ADDRESS
                  ? i18n.set_as_default_shipping_address
                  : i18n.set_as_default_billing_address
              }
              checked={makeDefault}
              onChange={() => setMakeDefault(!makeDefault)}
              name="rememberEmail"
            />
          </div>
        </div>
        <Box className={classes.lgButtonBox}>
          <Button
            onClick={() => onSubmit(values)}
            className={classes.dialogButton}
            // disabled={gettingLocation}
          >
            {i18n.checkout_update_label}
          </Button>
        </Box>
      </form>
    </div>
  )
}
