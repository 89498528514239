import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material'
import React from 'react'

export const CustomSelect = ({
  required,
  error,
  label,
  value,
  fullWidth,
  name,
  onChange,
  onBlur,
  helperText,
  options,
  variant,
  className,
  disabled,
}: SelectProps &
  FormControlProps & {
    options: { value: any; label: string }[]
    helperText?: string
  }) => {
  return (
    <FormControl required={required} error={error} fullWidth={fullWidth}>
      <InputLabel disabled={disabled}>{label}</InputLabel>
      <Select
        className={className}
        value={value}
        fullWidth={fullWidth}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        variant={variant}
        disabled={disabled}
      >
        {options.map((option, index) => (
          <MenuItem key={`option-${index}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
