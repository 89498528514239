import { ClassNameMap } from '@mui/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import HomeIcon from '@mui/icons-material/Home'
import { ReactComponent as OnDelivery } from '../../../assets/icons/onDelivery.svg'
import AvailableSoon from '../../../assets/img/treatment-plan/available-soon.png'
import CallMissed from '../../../assets/img/treatment-plan/call-missed.png'
import Connecting from '../../../assets/img/treatment-plan/connecting.png'
import FVConsultScheduled from '../../../assets/img/treatment-plan/consult-scheduled.png'
import Delivery from '../../../assets/img/treatment-plan/delivery.png'
import EasableHourglass from '../../../assets/img/treatment-plan/easable_hour_glass.png'
import Expired from '../../../assets/img/treatment-plan/expired.png'
import FVAvailableSoon from '../../../assets/img/treatment-plan/fv-available-soon.png'
import FVCallMissed from '../../../assets/img/treatment-plan/fv-call-missed.png'
import FVCarePlan from '../../../assets/img/treatment-plan/fv-care-plan.png'
import AltPharmLoc from '../../../assets/img/treatment-plan/fvf-alt-pharmacy-loc.png'
import Cancelled from '../../../assets/img/treatment-plan/fvf-cancelled.png'
import NoTreatment from '../../../assets/img/treatment-plan/no-treatment.png'
import Upcoming from '../../../assets/img/treatment-plan/PhoneConsult_comingup.png'
import PickupReady from '../../../assets/img/treatment-plan/pickup-ready.png'
import Preparing from '../../../assets/img/treatment-plan/preparing.png'
import InReview from '../../../assets/img/treatment-plan/Reviewing File_Image.png'
import EasableMissedCall from '../../../assets/img/treatment-plan/StatusCard_ErrorMissedVisit.png'
import EasableTreatmentReady from '../../../assets/img/treatment-plan/StatusCard_PickupAtPharmacy.png'
import EasableVideoScheduled from '../../../assets/img/treatment-plan/StatusCard_SyncVideoScheduled.png'
import ConsultMissed from '../../../assets/img/treatment-plan/telehealth-missed-call.png'
import Consultation from '../../../assets/img/treatment-plan/video-consultation.png'
import PlanReady from '../../../assets/img/treatment-plan/visit-summary.png'

const MUIIconStyle = { fill: 'inherit', fontSize: 20 }

export const Images = (classes?: ClassNameMap) => ({
  ConnectingImage: () => <img src={Connecting} className={classes?.image} />,
  PreparingImage: () => <img src={Preparing} className={classes?.smallImage} />,
  InReviewImage: () => <img src={InReview} className={classes?.smallImage} />,

  UpcomingImage: () => <img src={Upcoming} className={classes?.image} />,
  CancelledImage: () => <img src={Cancelled} className={classes?.image} />,
  ConsultMissedImage: () => (
    <img src={ConsultMissed} className={classes?.image} />
  ),
  CallMissedImage: () => <img src={CallMissed} className={classes?.image} />,
  NoTreatmentImage: () => <img src={NoTreatment} className={classes?.image} />,
  PlanReadyImage: () => <img src={PlanReady} className={classes?.image} />,
  AvailableSoonImage: () => (
    <img src={AvailableSoon} className={classes?.image} />
  ),
  PickupReadyImage: () => <img src={PickupReady} className={classes?.image} />,
  DeliveryImage: () => <img src={Delivery} className={classes?.image} />,
  ExpiredImage: () => <img src={Expired} className={classes?.image} />,
  AltPharmLocImage: () => <img src={AltPharmLoc} className={classes?.image} />,
  FVConsultScheduledImage: () => (
    <img src={FVConsultScheduled} className={classes?.image} />
  ),
  FVCallMissedImage: () => (
    <img src={FVCallMissed} className={classes?.image} />
  ),
  FVAvailableSoonImage: () => (
    <img src={FVAvailableSoon} className={classes?.image} />
  ),
  FVCarePlanImage: () => <img src={FVCarePlan} className={classes?.image} />,
  // ForDelivery: () => <DoorDash style={{ fill: 'inherit' }} />,
  ForDelivery: () => <LocalShippingIcon style={MUIIconStyle} />,
  EnRoute: () => <OnDelivery style={{ fill: 'inherit' }} />,
  Delivered: () => <HomeIcon style={MUIIconStyle} />,
  Complete: () => <CheckCircleIcon style={MUIIconStyle} />,
  ConsultationImage: () => (
    <img src={Consultation} className={classes?.image} />
  ),

  EasableMissedCallImage: () => (
    <img src={EasableMissedCall} className={classes?.image} />
  ),
  EasableHourGlassImage: () => (
    <img src={EasableHourglass} className={classes?.image} />
  ),
  EasableTreatmentReadyImage: () => (
    <img src={EasableTreatmentReady} className={classes?.image} />
  ),
  EasableVideoScheduledImage: () => (
    <img src={EasableVideoScheduled} className={classes?.image} />
  ),
})

export const progressSteps = [
  {
    key: 'doorDash',
    image: Images().ForDelivery,
  },
  {
    key: 'onDelivery',
    image: Images().EnRoute,
  },
  {
    key: 'delivered',
    image: Images().Delivered,
  },
  {
    key: 'complete',
    image: Images().Complete,
  },
]
