import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp'
import CloseIcon from '@mui/icons-material/Close'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { Box, Dialog } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ReactComponent as DeclineImage } from '../../assets/img/treatment-plan/decline-plan.svg'
import DefaultHeaderLarge from '../../assets/img/visit_summary.jpg'
import { displayFields } from '../../features/details-helper/pageBuilder'
import {
  getEncounterRecord,
  selectEncounter,
} from '../../features/encounter/model'
import {
  confirmTreatmentPlan,
  declineTreatmentPlan,
  getTreatmentPlan,
  selectFVFTreatmentPlan,
} from '../../features/health-service-lite/model'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { useMUITheme } from '../../libs/hooks/useTheme'
import { lsClient } from '../../ls-client'
import { Button, Checkbox, Typography } from '../../ui'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { selectNetworkSettings } from '../network/model'
import { TelehealthOptions } from '../network/type'
import { paths } from '../paths'
import {
  getArticleResources,
  getVideoResources,
  selectArticlesList,
  selectResourcesLoaded,
  selectVideosList,
} from '../resources/resourcesSlice'
import {
  ArticleResource,
  ResourceType,
  VideoResource,
} from '../resources/types'
import { carePlanStyles } from './carePlanStyles'
import {
  collectionResultFields,
  transformFVFTreatment,
  transformSAFETreatment,
} from './helper'

export const TreatmentPlanPage = () => {
  const { prescriptionId } = useParams<{ prescriptionId: string }>()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const classes = carePlanStyles({ radius: 0 })
  const visitSummary = useSelector(selectEncounter)
  const treatmentPlan = useSelector(selectFVFTreatmentPlan)
  const i18n = useSelector(selectLanguageContent)
  const currentNetwork = useSelector(selectNetworkSettings)
  const [treatmentDetails, setTreatmentDetails] = useState<any>({})
  const [declineTreatmentDialog, setDeclineTreatmentDialog] = useState(false)
  const [localDelivery, setLocalDelivery] = useState(false)
  const [isAttestationConfirmed, setIsAttestationConfirmed] = useState(false)
  const videoResources = useSelector(selectVideosList)
  const articleResources = useSelector(selectArticlesList)
  const resourcesLoaded = useSelector(selectResourcesLoaded)
  const primaryColor = useMUITheme().colors.primary

  // useEffect(() => {
  //   if (!resourcesLoaded) {
  //     dispatch(getVideoResources(''))
  //     dispatch(getArticleResources(''))
  //   }
  // }, [])

  useEffect(() => {
    window.scrollTo({ top: 0 })
    if (
      currentNetwork?.telehealthConfiguration ===
      TelehealthOptions.FIRST_VIEW_FINANCIAL
    ) {
      dispatch(getTreatmentPlan(prescriptionId || ''))
    } else {
      dispatch(getEncounterRecord(prescriptionId || ''))
    }
  }, [prescriptionId])

  const returnToDashboard = () => {
    navigate(paths.app.dashboard())
  }

  useEffect(() => {
    if (treatmentPlan || visitSummary) {
      if (
        currentNetwork?.telehealthConfiguration ===
        TelehealthOptions.FIRST_VIEW_FINANCIAL
      ) {
        setTreatmentDetails(transformFVFTreatment(treatmentPlan))
      } else {
        setTreatmentDetails(transformSAFETreatment(visitSummary))
      }
    }
  }, [treatmentPlan, currentNetwork, visitSummary])

  const onConfirm = () => {
    const params = {
      shippingMethod: localDelivery ? 'local_delivery' : 'pick_up',
      address: treatmentDetails.shipping_address,
      isAttestationConfirmed,
    }

    dispatch(confirmTreatmentPlan(params, prescriptionId || '', returnToDashboard))
  }

  const handleBack = () => {
    if (lsClient.navigation.getBackPath()) {
      lsClient.navigation.removeBackPath()
      navigate(paths.treatmentPlanHistory())
    } else {
      navigate(paths.app.dashboard())
    }
  }

  const declinePlan = () => {
    const params = {
      cancellationReason: 'user_cancelled',
    }

    dispatch(declineTreatmentPlan(params, prescriptionId || '', returnToDashboard))
  }

  const closeDialog = () => {
    setDeclineTreatmentDialog(false)
  }
  const onClick = (type: ResourceType, id: string) => {
    navigate(paths.resourceDetails(type, id))
  }
  const getText = (html: string) => {
    const temp = document.createElement('div')
    temp.innerHTML = html

    return temp.textContent
  }

  return (
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={handleBack}
      color="#505358"
    >
      <div className={classes.summaryWrapper}>
        <img src={DefaultHeaderLarge} className={classes.headerImage} />
        <div className={classes.content}>
          <Typography className={classes.banner}>
            {visitSummary?.disposition === 'CANCELLED'
              ? 'This Session was cancelled'
              : i18n.treatment_plan_lets_review}
          </Typography>
          <Box className={classes.innerWrapper}>
            {treatmentDetails &&
              displayFields(
                collectionResultFields.VISIT_SUMMARY_SAFE.fields,
                i18n,
                treatmentDetails
              )}
            {currentNetwork?.telehealthConfiguration ===
              TelehealthOptions.FIRST_VIEW_FINANCIAL && (
              <>
                <Box className={classes.optionWrapper}>
                  <Box className={classes.testDescription}>
                    <Typography className={classes.title}>
                      Preferred Address
                    </Typography>
                    <div className={classes.addressContainer}>
                      <div className={classes.addressLine1}>
                        <Typography>
                          {treatmentDetails.shipping_address?.street_1}
                        </Typography>
                        <div style={{ display: 'flex' }}>
                          <Typography>Edit</Typography>
                          <ChevronRightSharpIcon />
                        </div>
                      </div>
                      {treatmentDetails.shipping_address?.street_2 && (
                        <Typography>
                          {treatmentDetails.shipping_address.street_2}
                        </Typography>
                      )}
                      <Typography>{`${treatmentDetails.shipping_address?.city}, ${treatmentDetails.shipping_address?.state} ${treatmentDetails.shipping_address?.zip}`}</Typography>
                    </div>
                    <Typography variant="subtitle2" className={classes.info}>
                      We will use this address to select the pharmacy closest to
                      you. If delivery is available, we will deliver your
                      prescription to this address.
                    </Typography>
                    <div className={classes.delivery}>
                      <Checkbox
                        label={''}
                        checked={localDelivery}
                        onChange={() => setLocalDelivery(!localDelivery)}
                      />
                      <Typography
                        className={classes.title}
                        style={{ marginLeft: '-20px' }}
                      >
                        Use free 2-hour delivery if available
                      </Typography>
                    </div>
                    <Typography variant="subtitle2" className={classes.info}>
                      By selecting this checkbox, we will verify if same-day
                      delivery is available in your area. If not, we will let
                      you know where to pick up your prescription.{' '}
                    </Typography>
                  </Box>
                </Box>
                <Button className={classes.button} onClick={onConfirm}>
                  Confirm Treatment Plan
                </Button>
                <Box
                  className={classes.declineWrapper}
                  onClick={() => setDeclineTreatmentDialog(true)}
                >
                  <Typography
                    variant="body1"
                    color="primary"
                    className={classes.declineText}
                  >
                    Decline Treatment Plan
                  </Typography>
                </Box>
              </>
            )}
            {/* <div className={classes.resources}>
              <Resources
                i18n={i18n}
                videoResources={videoResources}
                articleResources={articleResources}
              />
            </div> */}
          </Box>
          <DeclineTreatmentDialog
            open={declineTreatmentDialog}
            decline={declinePlan}
            cancel={closeDialog}
          />
        </div>
      </div>
    </ModuleHeader>
  )
}

const Resources = (props: {
  videoResources: VideoResource[]
  articleResources: ArticleResource[]
  i18n: I18n
}) => {
  const { videoResources, articleResources, i18n } = props
  const navigate = useNavigate()
  const networkSettings = useSelector(selectNetworkSettings)
  const radius = networkSettings?.buttonStyle === 0 ? 8 : 28
  const classes = carePlanStyles({ radius })
  const hasVideos = videoResources.length > 0

  const onClick = (type: ResourceType, id: string) => {
    navigate(paths.resourceDetails(type, id))
  }

  const firstResource = videoResources?.[0] || articleResources?.[0]

  if (!firstResource) return null

  return (
    <Box className={classes.resourceWrapper}>
      <Typography className={classes.resourcesTitle}>
        {i18n.treatment_plan_resources_title}
      </Typography>

      <div className={classes.largeCard}>
        <Box
          className={classes.largeImageContainer}
          style={{
            cursor: 'pointer',
            backgroundImage: `url(${firstResource.thumbnailURL})`,
          }}
          onClick={() =>
            onClick(
              videoResources?.[0] ? 'video' : 'article',
              firstResource._id
            )
          }
        >
          {hasVideos && (
            <PlayArrowIcon className={`${classes.iconFilled} large`} />
          )}
        </Box>
        <Typography
          className={classes.dashboardButtonBody}
          style={{ marginTop: 16, fontWeight: 600 }}
        >
          {firstResource.title}
        </Typography>
      </div>
      <Box className={classes.secondaryItems}>
        {videoResources &&
          videoResources.length > 0 &&
          videoResources.map((r, i) => (
            <React.Fragment key={`${r._id}-${i}`}>
              {i === 0 ? null : (
                <Box className={classes.itemContainer}>
                  <Box
                    className={classes.smallImageContainer}
                    style={{
                      backgroundImage: `url(${
                        r.largeImageURL || r.thumbnailURL
                      })`,
                    }}
                    onClick={() => onClick('video', r._id)}
                  >
                    <PlayArrowIcon className={classes.iconFilled} />
                  </Box>
                  <Typography className={classes.secondaryText}>
                    {r.title}
                  </Typography>
                </Box>
              )}
            </React.Fragment>
          ))}
        {articleResources &&
          articleResources.length > 0 &&
          articleResources.map((r, i) => (
            <React.Fragment key={`${r._id}-${i}`}>
              {i === 0 && !hasVideos ? null : (
                <Box className={classes.itemContainer}>
                  <Box
                    className={classes.smallImageContainer}
                    style={{
                      backgroundImage: `url(${r.imageURL})`,
                    }}
                    onClick={() => onClick('video', r._id)}
                  />
                  <Typography className={classes.secondaryText}>
                    {r.title}
                  </Typography>
                </Box>
              )}
            </React.Fragment>
          ))}
      </Box>
    </Box>
  )
}

const DeclineTreatmentDialog = (props: {
  open: boolean
  cancel: () => void
  decline: () => void
}) => {
  const { open, cancel, decline } = props
  const classes = carePlanStyles({ radius: 0 })

  return (
    <Dialog open={open}>
      <div className={classes.dialogContent}>
        <div className={classes.closeWrapper}>
          <div className={classes.declineHeaderImage}>
            <DeclineImage className={classes.declineImage} />
          </div>
          <CloseIcon className={classes.closeIcon} onClick={cancel} />
        </div>
        <Typography variant="h2" className={classes.dialogHeader}>
          Are You Sure?
        </Typography>
        <Typography
          variant="body1"
          align="center"
          className={classes.dialogBody}
        >
          By cancelling your current Health Service, you will lose your
          progress. If you still need treatment, we recommend reaching out to
          your primary health care provider.
        </Typography>
        <Button
          className={classes.button}
          onClick={decline}
          style={{ margin: 0 }}
        >
          Cancel Health Service
        </Button>
      </div>
    </Dialog>
  )
}
