import { Box } from '@mui/material'
import React from 'react'
import { HTMLTextComponent } from '../../../../libs/shared-components'
import { Button, Typography } from '../../../../ui'
import { getButtonVariant } from '../../../../ui/atoms/button/button'
import { dashboardCardStyles } from '../../../_styles/dashboardCardStyles'
import { CardProps } from './types'

export const BackgroundImageCard = (props: CardProps) => {
  const { button, radius, handleClick } = props
  const classes = dashboardCardStyles({ radius })

  if (!button.cardImageUrl) return null

  return (
    <Box
      key={button.cardTitle}
      sx={{
        borderRadius: radius,
      }}
      className={classes.bgImageButtonContainer}
      onClick={handleClick}
    >
      <img
        src={button.cardImageUrl}
        style={{
          borderRadius: radius,
        }}
      />
      <Box className={classes.bgImageCard}>
        <Typography className={classes.dashboardButtonTitle}>
          <HTMLTextComponent HTMLString={button.cardTitle} />
        </Typography>
        {button.cardBody && (
          <Typography className={classes.dashboardButtonBody}>
            <HTMLTextComponent HTMLString={button.cardBody} />
          </Typography>
        )}
        {button.buttonEnabled && (
          <div className={classes.buttonWrapper}>
            <Button
              style={{
                backgroundColor:
                  button.buttonColor && button.buttonStyle === 0
                    ? button.buttonColor
                    : 'unset',
                borderColor: button.buttonColor ? button.buttonColor : 'unset',
                color: button.buttonLabelColor
                  ? button.buttonLabelColor
                  : 'unset',
              }}
              onClick={handleClick}
              className={classes.confirmButton}
              variant={getButtonVariant(button.buttonStyle)}
            >
              {button.buttonLabel}
            </Button>
          </div>
        )}
      </Box>
    </Box>
  )
}
