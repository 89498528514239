import { Link as MUILink } from '@mui/material'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StepType } from '../../../../constants'
import { VerificationCode } from '../../../../features/code-verification/code-verification'
import { selectLanguageContent } from '../../../../features/translation'
import {
  resetError as resetUserError,
  selectUserError,
  selectUserWarning,
  sendPhoneVerifyCode,
  verifyPhone,
} from '../../../../features/user/model/userTenantSlice'
import { isLoggedIn } from '../../../../libs/helpers'
import { formatPhoneForDisplayUS } from '../../../../libs/utils'
import { ContentLayout } from '../../../../ui'
import { onboardingStyles } from '../../../_styles/registrationStyles'
import {
  getProfileData,
  selectProfileData,
  updateUserContact,
} from '../../../profile/model'
import {
  requestRegistrationEmail,
  resetError,
  selectRegisterError,
  selectRegisterWarning,
  selectVerificationSubmitting,
  verifyRegistrationEmailCode,
} from '../../model/registerSlice'
import { RegisterStepProps } from '../../model/type'

interface ConfirmProps extends RegisterStepProps {
  type: StepType.SIGN_UP | StepType.ENTER_PHONE_NUMBER
}

export const Confirmation = (props: ConfirmProps) => {
  const { handleNext, formik, type, accountId, tenantId } = props
  const classes = onboardingStyles()
  const i18n = useSelector(selectLanguageContent)
  const dispatch = useDispatch()
  const loggedIn = isLoggedIn()
  const error = useSelector(selectRegisterError)
  const warning = useSelector(selectRegisterWarning)
  const phoneAPIError = useSelector(selectUserError)
  const phoneAPIWarning = useSelector(selectUserWarning)
  const profile = useSelector(selectProfileData)
  const [submitReady, setSubmitReady] = useState(false)
  const [code, setCode] = useState('')
  const submitting = useSelector(selectVerificationSubmitting)
  const email = localStorage.getItem('email') || ''

  // Override Safari cached load on browser back action
  // eslint-disable-next-line unicorn/prefer-add-event-listener
  window.onpageshow = function (event) {
    if (event.persisted) {
      window.location.reload()
    }
  }

  // useEffect(() => {
  //   if (loggedIn && !profile._id) {
  //     getProfileData(true)
  //   }
  // }, [loggedIn, profile])

  useEffect(() => {
    if (
      type === StepType.ENTER_PHONE_NUMBER &&
      !sessionStorage.getItem('codeSent') &&
      tenantId &&
      profile._id
    ) {
      sessionStorage.setItem('codeSent', 'sending')
      sendCode()
    }

    return () => {
      dispatch(resetUserError())
      dispatch(resetError())
    }
  }, [tenantId])

  useEffect(() => {
    if (submitReady) submitVerifyCode()
  }, [submitReady])

  const submitVerifyCode = () => {
    //Submit Email Verification code
    if (type === StepType.SIGN_UP) {
      const data = {
        email,
        pin: code,
        tenantId,
        accountId,
      }
      dispatch(verifyRegistrationEmailCode(data, i18n, handleNext))
    }

    //Submit Phone Verification code and if successful, save contact number
    if (type === StepType.ENTER_PHONE_NUMBER) {
      const values = formik.values[type]
      const { _id } = profile
      const mobileNumber = values.mobileNumber

      const saveContactCallback = () => {
        const data = {
          tenantId,
          _id,
          contact: {
            mobileCountryCode: '+1',
            mobileNumber: values.mobileNumber,
          },
        }
        dispatch(updateUserContact(data, false, handleNext))
      }

      const data = {
        _id,
        mobileNumber,
        mobileCountryCode: '+1',
        tenantId,
        code,
      }
      dispatch(verifyPhone(data, saveContactCallback))
    }
  }

  const sendCode = (reSend = false) => {
    // Request Email code
    if (type === StepType.SIGN_UP) {
      const values = formik.values[type]
      const data = {
        ...values,
        tenantId,
        accountId,
      }
      dispatch(requestRegistrationEmail(data, reSend, i18n))
    }
    // Request Phone SMS code
    if (type === StepType.ENTER_PHONE_NUMBER) {
      const values = formik.values[type]
      const { _id } = profile
      const mobileNumber = values.mobileNumber
      const data = {
        _id,
        mobileNumber,
        mobileCountryCode: '+1',
        tenantId,
      }
      dispatch(sendPhoneVerifyCode(data, reSend, i18n))
    }
  }

  const clearAPIMessages = () => {
    dispatch(resetError())
    dispatch(resetUserError())
  }

  const confirmationText = () => {
    if (type === StepType.SIGN_UP) {
      const values = formik.values[type]
      const description = i18n.verify_email_instruction?.replace(
        '%@',
        values?.email
      )
      return (
        <>
          <Typography className={classes.title} variant="h2" align="left">
            {i18n.verify_email_header}
          </Typography>
          <Typography className={classes.description}>{description}</Typography>
        </>
      )
    }

    const values = formik.values[type]
    const description = i18n.verify_phone_number_instruction?.replace(
      '%@',
      formatPhoneForDisplayUS(values?.mobileNumber)
    )
    return (
      <>
        <Typography className={classes.title} variant="h2" align="left">
          {i18n.verify_phone_number}
        </Typography>
        <Typography className={classes.description}>{description}</Typography>
      </>
    )
  }

  return (
    <>
      <ContentLayout className={classes.content}>
        {confirmationText()}
        <VerificationCode
          setVerificationCode={setCode}
          setSubmitReady={setSubmitReady}
          verifyError={error || phoneAPIError}
          warning={warning || phoneAPIWarning}
          clearError={clearAPIMessages}
          submitting={submitting}
        />
        <MUILink
          role="button"
          className={classes.pageLink}
          onClick={() => sendCode(true)}
          style={{ color: '#7E7E7E' }}
        >
          Re-send Code
        </MUILink>
      </ContentLayout>
    </>
  )
}
