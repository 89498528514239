import { Box, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hexToRGBA } from '../../../libs/helpers'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { getColor } from '../../../libs/utils'
import { Typography } from '../../../ui'
import { getQuestionnaireColors } from '../outcomes/helpers/utils'
import {
  selectCurrentQuestion,
  selectExistingAnswer,
  selectQuestionnaireColor,
  setAutoSubmit,
  updateAnswer,
} from '../questionnaireV2Slice'
import { Option } from '../types'

export const YesNoQuestion = () => {
  const dispatch = useDispatch()
  const questionnaireColor = useSelector(selectQuestionnaireColor)
  const color = getQuestionnaireColors(questionnaireColor)
  const question = useSelector(selectCurrentQuestion)
  const [answer, setAnswer] = useState('')
  const existingAnswer = useSelector(selectExistingAnswer)
  const { theme } = useMUITheme()

  useEffect(() => {
    if (existingAnswer) {
      dispatch(updateAnswer(existingAnswer.value))
      setAnswer(existingAnswer.value)
    }
  }, [question, existingAnswer])

  const answerChanged = (value: string) => {
    setAnswer(value)
    dispatch(updateAnswer(value))
    dispatch(setAutoSubmit(true))
  }

  const optionList = () => {
    return question.options?.map((option: Option) => (
      <YesNo
        key={option.value.translations.eng}
        onClick={() => answerChanged(option.value.translations.eng)}
        sx={{
          transition: 'all .03s ease-in-out',
          backgroundColor: answer.includes(option.value.translations.eng)
            ? hexToRGBA(getColor(color, theme), 0.1)
            : '#FFF',
          [theme.breakpoints.up(960)]: {
            '&:hover': {
              transition: 'all .03s ease-in-out',
              backgroundColor: hexToRGBA(getColor(color, theme), 0.05),
            },
          },
        }}
      >
        <Text>{option.value.translations.eng}</Text>
      </YesNo>
    ))
  }
  return <ListWrapper>{optionList()}</ListWrapper>
}

const YesNo = styled(Box)({
  cursor: 'pointer',
  height: 171,
  width: 171,
  border: '2px solid #E0E0E0',
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const ListWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-evenly',
  [theme.breakpoints.down(600)]: {
    marginBottom: 30,
    justifyContent: 'space-between',
  },
}))

const Text = styled(Typography)({
  fontSize: 36,
  fontWeight: 500,
})
