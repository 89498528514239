import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp'
import { Box, styled, Theme, useMediaQuery } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import GetTestedImage from '../../assets/img/HealthService.svg'
import { lsClient } from '../../ls-client'
import { selectNetworkSettings } from '../../pages/network/model'
import { paths } from '../../pages/paths'
import { selectProfileData } from '../../pages/profile/model'
import { Typography } from '../../ui'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { ModuleIntroWrapper } from '../../ui/templates/modules/moduleIntroWrapper'
import { updateEncounterRecord } from '../encounter/model'
import { createHealthService } from '../health-service-lite/model'
import { selectLanguageContent } from '../translation'
import { useFlowControl } from './use-flow-control'

export interface FlowsListProps {}

export const FlowsList = (props: FlowsListProps) => {
  const { state, actions } = useFlowControl()
  const navigate = useNavigate()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(960))
  const dispatch = useDispatch()
  const user = useSelector(selectProfileData)
  const networkSettings = useSelector(selectNetworkSettings)
  const selectedIds = sessionStorage.getItem('workflowListIds')
  const i18n = useSelector(selectLanguageContent)
  const hasActiveHSId = lsClient.getUserLSByKey('healthServiceId')

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  const startFlow = (flow: any) => {
    lsClient.setUserLS('flowId', flow.id)
    lsClient.setUserLS('flowTitle', flow.name.replaceAll('’', "'"))

    if (hasActiveHSId) {
      const encounterParams = {
        _id: hasActiveHSId,
        workflowId: flow.id,
        workflowTitle: flow.name,
      }
      dispatch(
        updateEncounterRecord(encounterParams, () => actions.startFlow(flow.id))
      )
    } else {
      const params = {
        tenantId: networkSettings?.tenantID,
        accountId: networkSettings?.accountId,
        UserId: user._id,
        Description: 'Starting Health Service',
      }
      const onCompletion = (healthServiceId: string) => {
        lsClient.setUserLS('healthServiceId', healthServiceId)
        actions.startFlow(flow.id)
      }
      dispatch(createHealthService(params, onCompletion))
    }
  }

  const handleClose = () => {
    lsClient.removeUserKeyLS('flowId')
    lsClient.removeUserKeyLS('flowTitle')
    lsClient.removeUserKeyLS('flowExecution')
    sessionStorage.removeItem('workflowListIds')
    navigate(paths.app.dashboard())
  }

  return (
    // <ModuleIntroWrapper
    //   contentImage={isMobile ? undefined : GetTestedImage}
    //   childPosition="center"
    //   contentPosition="start"
    //   title={'Health Services'}
    //   body={
    //     'Select a health service to see if you qualify for on demand treatment:'
    //   }
    //   onClose={handleClose}
    // >
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={handleClose}
      progressColor="secondary"
      color="#505358"
    >
      <ContentWrapper>
        <BannerTitle>{i18n.health_services_title}</BannerTitle>
        <BannerDesc>{i18n.health_services_text}</BannerDesc>
        <ListWrapper>
          {state.flowsList.map((flow: any) => {
            if (selectedIds && !selectedIds.includes(flow.id)) return null

            return (
              <FlowContainer onClick={() => startFlow(flow)} key={flow.id}>
                <ListItem>
                  <div>
                    <Title>{flow.name}</Title>
                    <Body>{flow.description}</Body>
                  </div>
                  <ChevronRightSharpIcon />
                </ListItem>
              </FlowContainer>
            )
          })}
        </ListWrapper>
      </ContentWrapper>
    </ModuleHeader>
  )
}

const FlowContainer = styled(Box)(({ theme }) => ({
  'borderRadius': '10px',
  'backgroundColor': '#fff',
  'width': 'calc(50% - 16px)',
  'border': '1px solid #aaa',
  'cursor': 'pointer',
  '&:hover': {
    backgroundColor: '#ddd',
  },
  [theme.breakpoints.down(960)]: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  [theme.breakpoints.down(600)]: {
    width: 'calc(100% - 2px)',
  },
}))

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 800,
  display: 'flex',
  flexDirection: 'column',
  margin: '48px auto',
  [theme.breakpoints.down(960)]: {
    width: '90vw',
    margin: '24px auto',
  },
}))

const ListWrapper = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: 16,
  marginTop: 24,
  [theme.breakpoints.down(600)]: {
    flexDirection: 'column',
    // height: '70vh',
    flexWrap: 'nowrap',
    marginBottom: 48,
  },
}))

const ListItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '32px',
  maxWidth: '660px',
  [theme.breakpoints.down(960)]: {
    padding: '16px',
  },
}))

const BannerTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: 36,
  color: '#282D37',
})

const BannerDesc = styled(Typography)({
  fontWeight: 500,
  fontSize: 16,
  color: '#757575',
})

const Title = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  color: '#505358',
})

const Body = styled(Typography)({
  fontWeight: 500,
  fontSize: 14,
  color: '#898989',
})
